import { useEffect, useMemo } from 'react';
import { useBaggageContext } from 'context';
import { Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import ContactsList from 'components/ContactsList';
import { resetContext } from 'context/actions';
import SASButton from 'SASButton';

const ReportBaggageModal = ({ handleModal }) => {
  const navigate = useNavigate();
  const {
    state: {
      modal,
      texts = {}
    },
    dispatch
  } = useBaggageContext();

  const content = useMemo(() => {
    const contents = {
      delayed: {
        title: texts['travel-ssdbr-reportDelayedBag']?.title,
        description: texts['travel-ssdbr-reportDelayedBag']?.description
          ?.replace('href=', '')
          ?.replace('<a', '<a id="delayed-modal-list" '),
        button: texts['travel-ssdbr-label-reportButton']?.title,
        handleClick: () => {
          dispatch(resetContext());
          navigate(process.env.PUBLIC_URL + '/search');
        },
      },
      damaged: {
        title: texts['travel-ssdbr-reportDamaged']?.title,
        description: texts['travel-ssdbr-reportDamaged']?.description,
        button: texts['travel-ssdbr-label-airportContactListButton']?.title,
        handleClick: () => handleModal(true, 'contacts'),
      },
      contacts: {
        title: texts['travel-ssdbr-airportsContactInfo']?.title,
        description: texts['travel-ssdbr-airportsContactInfo']?.description,
        button: texts['travel-ssdbr-label-closeButton']?.title,
        handleClick: () => handleModal(false, ''),
      },
      delayedList: {
        title: 'List of airports',
        description: `<div style="display: flex;width: 100%;justify-content: space-between"><ul>
        <li>AAR - Aarhus</li>
        <li>ABZ - Aberdeen </li>
        <li>AAL - Alborg</li>
        <li>AMS - Amsterdam</li>
        <li>AYT - Antalya</li>
        <li>BEY - Beirut</li>
        <li>BIQ - Biarritz</li>
        <li>BLL - Billund</li>
        <li>BHX - Birmingham</li>
        <li>BLQ - Bologna</li>
        <li>CAG - Cagliari</li>
        <li>CTA - Catania</li>
        <li>CPH - Copenhagen</li>
        </ul><ul>
        <li>DUB - Dublin</li>
        <li>EDI - Edinburgh</li>
        <li>FLR - Florens</li>
        <li>GVA - Geneva</li>
        <li>GZP - Gazipaşa-Alanya</li>
        <li>LCA - Larnaca</li>
        <li>LHR - London Heathrow</li>
        <li>MAN - Manchester</li>
        <li>MRS - Marseilles</li>
        <li>LIN - Milan, Linate</li>
        <li>MXP - Milan, Malpensa</li>
        <li>MPL - Montpellier</li>
        <li>NAP - Napoli</li>
        </ul><ul>
        <li>NCE - Nice</li>
        <li>OLB - Olbia</li>
        <li>PMO - Palermo</li>
        <li>CDG - Paris, Charles de Gaulle</li>
        <li>PSA - Pisa</li>
        <li>KEF - Reykavik</li>
        <li>FCO - Rome</li>
        <li>PVG - Shanghai</li>
        <li>HND - Tokyo</li>
        <li>TRN - Turin</li>
        <li>VCE - Venice</li>
        <li>ZAD - Zadar</li>
        <li>ZRH - Zurich</li>
    </ul></div>
`,
        button: false,
      },
    };

    return contents[modal?.target];
  }, [modal.target, texts, navigate, handleModal]);

  useEffect(() => {
    if (modal.target === 'delayed') {
      setTimeout(() => {
        const el = document.getElementById('delayed-modal-list');
        if (el) {
          el.addEventListener('click', () => {
            handleModal(true, 'delayedList');
          });
        }
      }, 500);
    }
  }, [modal.target, handleModal]);

  const handleListModal = () => {
    handleModal(true, 'delayed');
  };

  return (
    <Modal
      open={
        modal?.open &&
        ['delayed', 'damaged', 'contacts', 'delayedList'].includes(
          modal?.target
        )
      }
      handleModal={
        modal.target === 'delayedList' ? handleListModal : handleModal
      }
    >
      <Grid
        flexDirection="column"
        container
        sx={{ p: { xs: '0', sm: '0 24px 24px' } }}
      >
        <Typography
          variant="h6"
          color="primary"
          sx={{ fontSize: '18px', mb: '35px' }}
        >
          {content?.title}
        </Typography>
        <Typography
          variant="p"
          dangerouslySetInnerHTML={{ __html: content?.description }}
        />
        <br />
        {modal?.target === 'contacts' && <ContactsList />}
        <br />
        {content?.button && (
          // <Button
          //   color="secondary"
          //   variant="contained"
          //   sx={{
          //     maxWidth: 'fit-content',
          //     fontSize: '14px',
          //     m: '25px 0 0 auto',
          //   }}
          //   onClick={content?.handleClick}
          // >
          //   {content?.button}
          // </Button>
          <SASButton
            sx={{
              maxWidth: 'fit-content',
              fontSize: '14px',
              m: '25px 0 0 auto',
            }}
            onClick={content?.handleClick}>{content?.button}</SASButton>
        )}
      </Grid>
    </Modal>
  );
};

export default ReportBaggageModal;
