import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from 'components/Layout';
import Home from 'pages/Home';
import Search from 'pages/Search';
import React, { Suspense } from 'react';
import Loader from 'components/Loader';
const Contacts = React.lazy(() => import('pages/Contacts'));

function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Routes>
            <Route path={`${process.env.PUBLIC_URL}`} element={<Home />} />
            <Route
              path={`${process.env.PUBLIC_URL}/search`}
              element={<Search />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/contacts`}
              element={
                <Suspense fallback={Loader}>
                  <Contacts />
                </Suspense>
              }
            />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
