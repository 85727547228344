import { useCallback, useState, Fragment, useEffect } from 'react';
import { Box, Button, Alert, Snackbar, Typography } from '@mui/material';
import { useFormik, FormikProvider } from 'formik';
import SectionWrapper from 'components/SectionWrapper';
import { useBaggageContext } from 'context';
import moment from 'moment';
import DeliveryAddress from './components/DeliveryAddress';
import PermanentAddress from './components/PermanentAddress';
import Notify from './components/Notify';
import Contacts from './components/Contacts';
import { contactInformationSchema } from 'helpers/validationSchema';
import { reportBaggage } from 'api/booking';
import {
  CONFIRM_REPORT_ERROR,
  CONFIRM_REPORT_LOADING,
  CONFIRM_REPORT_SUCCESS,
} from 'context/constants';
import { handleChangeState } from 'context/actions';
import TicketAndEBInfo from 'components/TicketAndEBInfo';
import SASButton from 'SASButton';
import { DELIVERY_OPTIONS } from 'helpers/constant';

const inputStyle = {
  maxWidth: { sm: '313px', xs: 'auto' },
};

const ContactInformation = () => {
  const {
    state: {
      customDeclaration,
      ebNumber,
      pnr,
      flights,
      bagTags,
      passenger,
      baggageDetails,
      contacts,
      notifyBy,
      deliveryTo,
      expandSections,
      isGroupBooking,
      bagTagNumber,
      flightNumber,
      departureDate,
      searchType,
      transactionId,
      texts: {
        'travel-ssdbr-label-contactInformation': title = '',
        'travel-ssdbr-label-continueButton': continueButton = '',
        'travel-ssdbr-label-submitButton': submitButton = {
          title: 'SUBMIT'
        },
        'travel-ssdbr-label-reset': resetButton = '',
        'travel-ssdbr-label-email': emailLabel = '',
        'travel-ssdbr-label-permanentaddress': permanentAddressLabel = '',
        'travel-ssdbr-label-error-generic': defaultError = '',
        'travel-ssdbr-label-error-minLastName': minLastNameError = {
          title: 'Last name should be of minimum 2 characters length',
        },
        'travel-ssdbr-label-error-maxLastName': maxLastNameError = {
          title: 'Last name should be of maximum 16 characters length',
        },
        'travel-ssdbr-label-error-invalidTempAddressValidity': invalidTempAddressValidity = {
          title: 'Every temporary address validity must be greater than previous',
        }
      } = {},
    },
    dispatch,
  } = useBaggageContext();
  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    message: '',
  });

  const validationValues = {
    firstName: {
      min: 2,
      max: 16,
    },
    lastName: {
      min: 2,
      max: 16,
    },
    email: {
      min: 6,
      max: 58,
    },
    phoneNumber: {
      min: 8,
      max: 20,
    },
    ticketNumber: {
      min: 14,
      max: 14,
    },
    ebNumber: {
      min: 12,
      max: 12,
    },
    'permanentAddress.city': {
      min: 1,
      max: 27,
    },
    'permanentAddress.address': {
      min: 0,
      max: 58,
    },
    'permanentAddress.postalCode': {
      min: 1,
      max: 12,
    },
    informativeTexts: {
      minLastNameError,
      maxLastNameError
    }
  };

  useEffect(() => {
    dispatch(
      handleChangeState({
        notifyBy: 'EMAIL',
        deliveryTo: DELIVERY_OPTIONS.PERMANENT_ADDRESS,
      })
    );
  }, [emailLabel, permanentAddressLabel, dispatch]);

  const handleChangeRadio = useCallback(
    (name, value) => dispatch(handleChangeState({ [name]: value })),
    [dispatch]
  );

  const formatPhoneNumber = (phoneNumber) => {
     if(phoneNumber?.startsWith('00')) {
      return '+' + phoneNumber?.slice(2);	
    }
    else {
      return '+' + phoneNumber;
    }
  }

  const handleSubmit = useCallback(
    async (values) => {
      let isTempAddValid = true;
      if (deliveryTo !== DELIVERY_OPTIONS.PERMANENT_ADDRESS) {
        const length = values?.deliveryAddress?.length;
        isTempAddValid = values?.deliveryAddress?.every((address, index) => {
          const nextIndex = index + 1;
          return nextIndex < length ? (moment(address?.validUntil).isBefore(moment(values.deliveryAddress?.[nextIndex].validUntil))) : true
        })
      }
      if (!isTempAddValid) {
        setShowSnackbar({ show: true, message: invalidTempAddressValidity?.title });
      }
      else {
        dispatch({ type: CONFIRM_REPORT_LOADING, payload: values });
        const body = {
          bagTagNumber,
          flightNumber,
          departureDate,
          transactionId: transactionId,
          airlineCode: flights ? flights[0]?.operatingCarrier : 'SK',
          noOfBags: Object.keys(baggageDetails).length,
          delayedBags: Object.entries(baggageDetails).map(([key, value]) => {
            const bagDetails = {
              bagTagNumber: key,
              colorCode: value?.bagColor?.code,
              description: value?.description,
              typeCode: value?.bagType?.code?.slice(0, 2),
              material: value?.bagType?.code?.slice(2),
            };
          
            if (value?.brand && value?.brand !== 'I don’t remember') {
              bagDetails.brand = value?.brand;
            }
          
            return bagDetails;
          }),
          passenger: {
            pnr,
            firstName: values?.firstName,
            lastName: values?.lastName,
            tier: '',
            ticketNumber:  values?.ticketNumber || '' ,
            language: 'ENG',
            email: values?.email,
            phone: values?.phoneNumber,
            messageOption: notifyBy,
            ffNumber: values?.ebNumber || "",
            permanentAddress: {
              ...values?.permanentAddress,
              country: values.permanentAddress.countryName?.code?.toLowerCase(),
              countryName: values.permanentAddress.countryName
                ?.name
                ?.trim(),
            },
          },
          segments: flights?.slice(-4)?.map((flight) => ({
            flightNumber: flight?.operatingFlightNumber,
            flightDate: moment(flight?.departure?.scheduledTime?.local).format(
              'YYYY-MM-DD'
            ),
            origin: flight?.departure?.station,
            destination: flight?.arrival?.station,
            carrier: flight?.operatingCarrier,
            arrival: flight?.arrival?.station,
            departure: flight?.departure?.station,
          })),
        };
        if (customDeclaration.length) {
          body.customsInfo = customDeclaration;
        }
        if (deliveryTo !== DELIVERY_OPTIONS.PERMANENT_ADDRESS)
          body.passenger.tempAddresses = values?.deliveryAddress?.map(
            (address) => ({
              ...address,
              country: address.countryName?.code?.trim()?.toLowerCase(),
              countryName: address.countryName?.name?.trim(),
            })
          );
        try {
          const response = await reportBaggage(body);
          if (response.status === 200 && response?.reportReference != null) {
            dispatch({
              type: CONFIRM_REPORT_SUCCESS,
              payload: { report: response },
            });
            dispatch(handleChangeState({ visitedTab: 4 }))
          }
          else {
            dispatch({ type: CONFIRM_REPORT_ERROR });
            setShowSnackbar({
              show: true,
              message: defaultError?.description || 'Sorry, something went wrong. Please contact SAS Baggage Service'
            });
            window.scrollTo({ top: 5000, behavior: 'smooth' });
          }
        } catch (err) {
          console.log(err);
          dispatch({ type: CONFIRM_REPORT_ERROR });
        }
      }
    },
    [
      notifyBy,
      deliveryTo,
      passenger,
      flights,
      baggageDetails,
      defaultError,
      pnr,
      bagTags,
      invalidTempAddressValidity,
      customDeclaration,
      dispatch
    ]
  );

  const formik = useFormik({
    initialValues: {
      ...contacts,
      firstName: contacts?.firstName?.slice(0, 16) || passenger?.firstName?.slice(0, 16) || '',
      lastName: contacts?.lastName?.slice(0, 16) || passenger?.lastName?.slice(0, 16) || '',
      email:
        contacts?.email ||
        (passenger &&
          passenger?.contact?.email?.find((email) => email?.type === 'CTCE')
            ?.address) ||
        '',
      phoneNumber:
        contacts?.phoneNumber?.length > 1
          ? contacts?.phoneNumber
          : passenger &&
            passenger?.contact?.phone
              ?.find((phone) => phone?.type === 'CTCM')
              ?.number?.startsWith('+')
            ? passenger?.contact?.phone?.find((phone) => phone?.type === 'CTCM')
              ?.number
            : passenger?.contact?.phone?.find((phone) => phone?.type === 'CTCM')
              ? formatPhoneNumber(passenger?.contact?.phone?.find((phone) => phone?.type === 'CTCM')?.number)
              : '',
      ticketNumber: passenger?.segments
      ? passenger?.segments?.find(seg => seg.ticket?.number)?.ticket?.number || ''
      : '',
      ebNumber: ebNumber || ''
    },
    enableReinitialize: true,
    validationSchema: contactInformationSchema(
      validationValues,
      deliveryTo,
      notifyBy,
      searchType
    ),
    onSubmit: handleSubmit,
  });


  const handleClose = () => setShowSnackbar({ show: false, message: '' });

  const checkErrors = useCallback(() => {
    if (
      Object.keys(formik.errors).some((ele) => ['email',
        'firstName',
        'lastName',
        'phoneNumber']
        .includes(ele))
    ) {
      const wrapper = document.getElementById('contacts-form');
      if (wrapper) wrapper.scrollIntoView({ behavior: 'smooth' });
    } else if (Object.keys(formik.errors).some((ele)=> ['ticketNumber', 'ebNumber'].includes(ele))) {
      const wrapper = document.getElementById('ticket-section');
      if (wrapper) wrapper.scrollIntoView({ behavior: 'smooth' });
    } else if (Object.keys(formik.errors).includes('permanentAddress')) {
      const wrapper = document.getElementById('permanent-address-form');
      if (wrapper) wrapper.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formik.errors]);

  const handleContinue = useCallback(() => {
    checkErrors();
    const button = document.getElementById('hidden-button-contact-form');
    if (button) button.click();
  }, [checkErrors]);

  return (
    <div>
      <Snackbar
        open={showSnackbar.show}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          <span
            dangerouslySetInnerHTML={{ __html: showSnackbar.message }}
          ></span>
        </Alert>
      </Snackbar>
      <SectionWrapper
        expand={expandSections?.contactInformation}
        id="contact-information-section"
        title={{
          jsx: title?.title,
        }}
        summaryText=""
        subtitle=""
        linkClick={() => formik.resetForm()}
        linkText={resetButton?.title}
        button={submitButton?.title}
        buttonClick={() => handleContinue()}
      >
        <FormikProvider value={formik}>
          <Box
            width={"inherit"}
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', mt: '20px' }}
            onSubmit={formik?.handleSubmit}
          >
            <Contacts
              formik={formik}
              inputStyle={inputStyle}
              validationValues={validationValues}
            />
            {
              (
                <TicketAndEBInfo
                  formik={formik}
                  inputStyle={inputStyle}
                  validationValues={validationValues}
                  searchType={searchType}
                />
              )
            }
            <Notify notifyBy={notifyBy} setNotifyBy={handleChangeRadio} />
            <PermanentAddress
              formik={formik}
              inputStyle={inputStyle}
              validationValues={validationValues}
            />
            <DeliveryAddress
              formik={formik}
              deliveryTo={deliveryTo}
              setDeliveryTo={handleChangeRadio}
              inputStyle={inputStyle}
              validationValues={validationValues}
            />
           {flights[flights.length-1]?.arrival?.station === 'AGA' && <Typography variant='p'  sx={{ m: { sm: '48px 0 12px 0', xs: '24px 0 12px 0' } }}
           dangerouslySetInnerHTML={{ __html: 'Note: As per national governmental decision, Please note there is no delivery of bag in Agadir (AGA) for delayed/missing bags. All passengers must come to the airport to pickup their bags.' }}>
            
            </Typography>}
            <SASButton id={'hidden-button-contact-form'}
              type="submit"
              sx={{ display: 'none' }}></SASButton>
          </Box>
        </FormikProvider>
      </SectionWrapper>
    </div>
  );
};

export default ContactInformation;
