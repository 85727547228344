import {
  Typography,
  Grid,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@mui/material';
import { MobileDateTimePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import Input from 'components/Input';
import { FieldArray, Field } from 'formik';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { useBaggageContext } from 'context';
import { useEffect } from 'react';
import CountryAutocomplete from 'components/CountryAutocomplete';
import { DELIVERY_OPTIONS } from 'helpers/constant';

const DeliveryAddress = ({ formik, deliveryTo, setDeliveryTo, inputStyle, validationValues }) => {
  const {
    state: {
      texts = {},
      texts: {
        'travel-ssdbr-label-heading-whereToSend': title = '',
        'travel-ssdbr-label-address': addressLabel = '',
        'travel-ssdbr-label-postalcode': postalcodeLabel = '',
        'travel-ssdbr-label-city': cityLabel = '',
        'travel-ssdbr-label-country': countryLabel = '',
        'travel-ssdbr-label-co': coLabel = '',
        'travel-ssdbr-label-validuntil': validUntilLabel = {
          title: 'Valid until',
        },
        'travel-ssdbr-label-addTempAddress': addAddress = '',
        'travel-ssdbr-label-removeTempAddress': removeAddress = {
          title: 'Remove',
        },
      } = {},
    },
  } = useBaggageContext();

  const deliveryOptions = useMemo(
    () => [ {label: texts['travel-ssdbr-label-permanentaddress']?.title, value: DELIVERY_OPTIONS.PERMANENT_ADDRESS }, 
            {label: texts['travel-ssdbr-label-temporaryaddress']?.title, value : DELIVERY_OPTIONS.TEMPORARY_ADDRESS }
    ],
    [texts]
  );

  const getFormikErrors = useCallback(
    (field, meta) => ({
      errors: {
        [field?.name]: meta?.error,
      },
      touched: {
        [field?.name]: meta?.touched,
      },
    }),
    []
  );

  useEffect(() => {
    formik.validateForm();
  }, [deliveryTo]);

  return (
    <FieldArray
      name="deliveryAddress"
      render={(arrayHelpers) => (
        <Grid container flexDirection="column">
          <Typography
            variant="baggageList"
            sx={{ m: { sm: '48px 0 12px 0', xs: '24px 0 12px 0' } }}
            dangerouslySetInnerHTML={{ __html: title?.title }}
          />
          <FormControl>
            <RadioGroup
              row
              name="delivery-radio-group"
              value={deliveryTo}
              onChange={(e) => setDeliveryTo('deliveryTo', e.target.value)}
            >
              {deliveryOptions?.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                  sx={{ mr: '45px' }}
                />
              ))}
            </RadioGroup>
          </FormControl>
          {formik?.values?.deliveryAddress?.map(
            (_, index, array) =>
              deliveryOptions[1].value === deliveryTo && (
                <Grid
                  key={index}
                  container
                  flexDirection="column"
                  mt="34px"
                  display={
                    index !== 0 && deliveryTo === 'Permanent address'
                      ? 'none'
                      : 'flex'
                  }
                >
                  <Grid
                    container
                    flexWrap="nowrap"
                    sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
                    justifyContent="space-between"
                  >
                    <Field name={`deliveryAddress[${index}].address`}>
                      {({ meta, field }) => (
                        <Input
                          name={`deliveryAddress[${index}].address`}
                          label={addressLabel?.title}
                          value={
                            formik?.values?.deliveryAddress[index]?.address
                          }
                          handleChange={formik?.handleChange}
                          style={inputStyle}
                          formik={getFormikErrors(field, meta)}
                          validvalues={validationValues['permanentAddress.address']}
                        />
                      )}
                    </Field>
                    <Field name={`deliveryAddress[${index}].postalCode`}>
                      {({ meta, field }) => (
                        <Input
                          name={`deliveryAddress[${index}].postalCode`}
                          label={postalcodeLabel?.title}
                          value={
                            formik?.values?.deliveryAddress[index]?.postalCode
                          }
                          handleChange={formik?.handleChange}
                          formik={getFormikErrors(field, meta)}
                          style={inputStyle}
                          validvalues={validationValues['permanentAddress.postalCode']}
                        />
                      )}
                    </Field>
                    {/* {deliveryTo === 'Temporary address' ? ( */}
                    <Field name={`deliveryAddress[${index}].co`}>
                      {({ field, meta }) => (
                        <Input
                          name={`deliveryAddress[${index}].co`}
                          label={coLabel?.title}
                          value={formik?.values?.deliveryAddress[index]?.co}
                          handleChange={formik?.handleChange}
                          formik={getFormikErrors(field, meta)}
                          style={{
                            ...inputStyle,
                            mr: '0',
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid
                    container
                    flexWrap="nowrap"
                    sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
                    justifyContent="space-between"
                  >
                    <Field name={`deliveryAddress[${index}].city`}>
                      {({ field, meta }) => (
                        <Input
                          name={`deliveryAddress[${index}].city`}
                          label={cityLabel?.title}
                          value={formik?.values?.deliveryAddress[index]?.city}
                          handleChange={formik?.handleChange}
                          formik={getFormikErrors(field, meta)}
                          style={inputStyle}
                          validvalues={validationValues['permanentAddress.city']}
                        />
                      )}
                    </Field>
                    <Field name={`deliveryAddress[${index}].countryName`}>
                      {({ field, meta }) => (
                        <CountryAutocomplete
                          handleChange={formik?.setFieldValue}
                          name={`deliveryAddress[${index}].countryName`}
                          label={countryLabel?.title}
                          value={formik?.values?.deliveryAddress[index]?.countryName}
                          formik={getFormikErrors(field, meta)}
                          style={inputStyle}
                        />
                      )}
                    </Field>
                    <Field name={`deliveryAddress[${index}].validUntil`}>
                      {({ field, meta }) => (
                        <LocalizationProvider dateAdapter={DateAdapter}>
                          <MobileDateTimePicker
                            minDate={moment()}
                            name={`deliveryAddress[${index}].validUntil`}
                            value={
                              formik?.values?.deliveryAddress[index]?.validUntil
                            }
                            onChange={(newValue) => {
                              if(newValue === null) return;
                              formik?.setFieldValue(
                                `deliveryAddress[${index}].validUntil`,
                                newValue?.format('DD MMMM YYYY HH:mm')
                              );
                            }}
                            label={validUntilLabel?.title}
                            inputFormat="DD MMMM YYYY HH:mm"
                            showToolbar={false}
                            renderInput={(params) => (
                              <Input
                                name={`deliveryAddress[${index}].validUntil`}
                                label={validUntilLabel?.title}
                                formik={getFormikErrors(field, meta)}
                                style={{
                                  ...inputStyle,
                                  mr: '0',
                                }}
                                params={params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    </Field>
                  </Grid>
                  <Grid container justifyContent="space-between">
                    <Typography
                      textAlign="center"
                      onClick={() => {
                        // const validDate = moment().format('DD MMMM YYYY HH:mm');
                        arrayHelpers.push({
                          city: '',
                          address: '',
                          countryName: '',
                          postalCode: '',
                          co: '',
                          validUntil: null,
                        })
                      }}
                      color="secondary"
                      sx={{
                        cursor: 'pointer',
                        display: array?.length - 1 === index ? 'initial' : 'none',
                      }}
                      dangerouslySetInnerHTML={{ __html: addAddress?.title }}
                    />
                    <Typography
                      textAlign="center"
                      onClick={() => arrayHelpers.remove(index)}
                      color="secondary"
                      sx={{
                        cursor: 'pointer',
                        ml: 'auto',
                        display: array?.length === 1 ? 'none' : 'initial',
                      }}
                      dangerouslySetInnerHTML={{ __html: removeAddress?.title }}
                    />
                  </Grid>
                </Grid>
              ))}
        </Grid>
      )}
    />
  );
};

export default DeliveryAddress;
