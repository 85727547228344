import { Grid, Box, Typography } from '@mui/material';
import { useState, useCallback, useMemo } from 'react';
import { useBaggageContext } from 'context';

const BaggageTypeSelect = ({ name, handleChange, formik, checkedBag }) => {
  const {
    state: {
      texts,
      texts: {
        'travel-ssdbr-label-bagType-subCategory1': tab1 = '',
        'travel-ssdbr-label-bagType-subCategory2': tab2 = '',
        'travel-ssdbr-label-bagType-subCategory3': tab3 = '',
        'travel-ssdbr-label-error-bagTypeRequired': errorLabel = '',
      } = {},
    },
  } = useBaggageContext();
  const [selectedTab, setSelectedTab] = useState(
    texts['travel-ssdbr-label-bagType-subCategory1']?.title
  );

  const bagTabs = useMemo(
    () => [tab1?.title, tab2?.title, tab3?.title],
    [tab1, tab2, tab3]
  );

  const content = useMemo(() => {
    return {
      [tab1?.title]: [
        {
          code: '20',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item1']?.title,
        },
        {
          code: '22',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item2']?.title,
        },
        {
          code: '22D',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item3']?.title,
        },
        {
          code: '22R',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item4']?.title,
        },
        {
          code: '23',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item5']?.title,
        },
        {
          code: '25',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item6']?.title,
        },
        {
          code: '26',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item7']?.title,
        },
        {
          code: '27',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item8']?.title,
        },
        {
          code: '28',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item9']?.title,
        },
        {
          code: '29',
          title: texts['travel-ssdbr-label-bagType-subCategory1-item10']?.title,
        },
      ],
      [tab2?.title]: [
        {
          code: '01',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item1']?.title,
        },
        {
          code: '02',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item2']?.title,
        },
        {
          code: '03',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item3']?.title,
        },
        {
          code: '05',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item4']?.title,
        },
        {
          code: '06',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item5']?.title,
        },
        {
          code: '07',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item6']?.title,
        },
        {
          code: '08',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item7']?.title,
        },
        {
          code: '09',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item8']?.title,
        },
        {
          code: '10',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item9']?.title,
        },
        {
          code: '12',
          title: texts['travel-ssdbr-label-bagType-subCategory2-item10']?.title,
        },
      ],
      [tab3?.title]: [
        {
          code: '73',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item1']?.title,
        },
        {
          code: '74',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item2']?.title,
        },
        {
          code: '62',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item3']?.title,
        },
        {
          code: '66',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item4']?.title,
        },
        {
          code: '68',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item5']?.title,
        },
        {
          code: '67',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item6']?.title,
        },
        {
          code: '63',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item7']?.title,
        },
        {
          code: '59',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item8']?.title,
        },
        {
          code: '72',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item9']?.title,
        },
        {
          code: '99',
          title: texts['travel-ssdbr-label-bagType-subCategory3-item10']?.title,
        },
      ],
    };
  }, [tab1, tab2, tab3, texts]);

  const handleSelectedTab = useCallback((tab) => setSelectedTab(tab), []);

  return (
    <Grid container flexDirection="column">
      <Grid container>
        {bagTabs?.map((tab, index) => (
          <Grid
            item
            xs={4}
            key={index}
            onClick={() => handleSelectedTab(tab)}
            textAlign="center"
            sx={{
              p: { xs: '12px 4px', sm: '16px 0' },
              cursor: 'pointer',
              bgcolor: ({ palette }) =>
                selectedTab === tab
                  ? palette?.background?.blue
                  : palette?.primary?.main,
            }}
          >
            <Typography
              variant="button"
              sx={{
                fontSize: { sm: '12px', xs: '10px' },
                color: '#ffffff',
              }}
            >
              {tab}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: {
            md: 'repeat(5, 1fr)',
            sm: 'repeat(4, 1fr)',
            xs: 'repeat(2, 1fr)',
          },
          mt: '18px',
        }}
      >
        {content[selectedTab]?.map((type) => (
          <Grid
            container
            flexDirection="column"
            justifyContent="space-between"
            key={type?.code}
            sx={{
              cursor: 'pointer',
              p:
                formik?.values[name] === type?.code
                  ? '8px 9px 13px'
                  : '10px 11px 15px',
              border:
                formik?.values[name] === type?.code
                  ? '3px solid #006ee6'
                  : '1px solid rgba(0, 0, 0, 0.2)',
              width: '100%',
              height: '193px',
            }}
            onClick={() => handleChange(type)}
          >
            <Typography variant="p" sx={{ fontSize: '12px', mb: '10px' }}>
              {type?.title}
            </Typography>
            <Box
              component="img"
              src={
                process.env.PUBLIC_URL + `/images/bags/bagtype${type?.code}.jpg`
              }
              sx={{
                width: '100%',
                maxWidth: { sm: '167px', xs: '100%' },
                height: '100px',
              }}
            />
          </Grid>
        ))}
      </Box>
      <Typography
        id={`baggage-type-err-${checkedBag}`}
        color="error"
        mt="10px"
        dangerouslySetInnerHTML={{
          __html:
            formik?.touched[name] && Boolean(formik?.errors[name])
              ? errorLabel?.description
              : '<p></p>',
        }}
      />
    </Grid>
  );
};

export default BaggageTypeSelect;
