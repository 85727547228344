import client from '.';
import { config } from 'config';

export const getBooking = async (params) => {
  let api = config.API_BASE_URL + '/travel-api/web/reservation?';

  Object.keys(params).forEach((key) => {
    api += `${key}=${params[key]}&`;
  });

  return await client(api, 'get');
};

export const getBagJourney = async (params) => {
  let api = config.API_BASE_URL + '/travel-api/web/bag/journey?';

  Object.keys(params).forEach((key) => {
    api += `${key}=${params[key]}&`;
  });

  return await client(api, 'get');
};

export const getReportBaggages = async (params) => {
  let api = config.API_BASE_URL + '/travel-api/web/bagReport/search?';

  Object.keys(params).forEach((key) => {
    api += `${key}=${params[key]}&`;
  });

  return await client(api, 'get');
};

export const reportBaggage = async (body) => {
  const api = config.API_BASE_URL + '/travel-api/web/bagReport';

  return await client(api, 'post', body);
};

export const checkFlight = async (values) => {
  const api = config.API_BASE_URL + '/travel-api/web/flights/status?' + values;

  return await client(api, 'get');
};

export const saveInitReport = async (body) => {
  const api = config.API_BASE_URL + '/travel-api/web/bagReport/init';
  return await client(api, 'post', body);
};