import {
  Typography,
  Grid,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@mui/material';
import { useBaggageContext } from 'context';
import { useMemo } from 'react';

const Notify = ({ notifyBy, setNotifyBy }) => {
  const {
    state: {
      texts = {},
      texts: { 'travel-ssdbr-label-heading-howToNotified': title = '' } = {},
    },
  } = useBaggageContext();

  const notifyOptions = useMemo(
    () => [
      { title: texts['travel-ssdbr-label-email']?.title, value: 'EMAIL' },
      {
        title: texts['travel-ssdbr-label-sms']?.title?.toUpperCase(),
        value: 'SMS',
      },
      {
        title: texts['travel-ssdbr-label-both']?.title,
        value: 'SMS_AND_EMAIL',
      },
    ],
    [texts]
  );

  return (
    <Grid container flexDirection="column" id="notify-section">
      <Typography
        variant="baggageList"
        sx={{ m: { sm: '48px 0 12px 0', xs: '24px 0 12px 0' } }}
        dangerouslySetInnerHTML={{ __html: title?.title }}
      />
      <FormControl>
        <RadioGroup
          row
          name="notify-radio-group"
          value={notifyBy}
          onChange={(e) => setNotifyBy('notifyBy', e.target.value)}
        >
          {notifyOptions?.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={option?.title[0].toUpperCase() + option.title.slice(1)}
              sx={{ mr: '45px' }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

export default Notify;
