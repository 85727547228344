import { Fragment, useEffect } from 'react';

const Loader = ({ open }) => {
  useEffect(() => {
    if (open) document.body.style.position = 'fixed';
    else document.body.style.position = 'static';
  }, [open]);

  return (
    <div>
      {open && (
        <div>
          <ul className="spinner white-bg">
            <li className="bounce1"></li>
            <li className="bounce2"></li>
            <li className="bounce3"></li>
          </ul>
          <style>{`
						.spinner {
							position: fixed;
							z-index: 9999 !important;
							left: 0;
							top: 0;
							right: 0;
							bottom: 0;
							height: 100%;
							width: 100%;
							padding: 0;
							margin: 0;
							display: -webkit-box;
							display: -moz-box;
							display: -ms-flexbox;
							display: -webkit-flex;
							display: flex;
							align-items: center;
							justify-content: center;
							background: #f5f5f5;
							overflow: hidden;
						}
						.spinner > li.bounce1,
						.spinner > li.bounce2,
						.spinner > li.bounce3 {
							width: 8px !important;
							height: 8px !important;
							margin-right: 2px;
							float: left;
							padding: 0 !important;
							background-color: #000066;
							border-radius: 100%;
							display: inline-block;
							-webkit-animation: cir-alphadelay 1.5s infinite ease-in-out both;
							-moz-animation: cir-alphadelay 1.5s infinite ease-in-out both;
							animation: cir-alphadelay 1.5s infinite ease-in-out both;
						}
						.spinner.white-bg > li {
							background-color: #000066;
						}
						.spinner > li.bounce1 {
							animation-delay: 0.1s;
							-webkit-animation-delay: 0.1s;
							-moz-animation-delay: 0.1s;
						}
						.spinner > li.bounce2 {
							-webkit-animation-delay: 0.3s;
							-moz-animation-delay: 0.3s;
							animation-delay: 0.3s;
						}
						.spinner > li.bounce3 {
							-webkit-animation-delay: 0.5s;
							-moz-animation-delay: 0.5s;
							animation-delay: 0.5s;
						}
						.spinner-bg p {
							color: #000066;
							font-size: 14px;
							text-align: center;
						}
						@keyframes cir-alphadelay {
							0% {
								filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=20);
								opacity: 0.2;
							}
							50% {
								filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
								opacity: 1;
							}
							100% {
								filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=20);
								opacity: 0.2;
							}
						}
						@-webkit-keyframes cir-alphadelay {
							0% {
								filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=20);
								opacity: 0.2;
							}
							50% {
								filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
								opacity: 1;
							}
							100% {
								filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=20);
								opacity: 0.2;
							}
						}
					`}</style>
        </div>
      )}
    </div>
  );
};

export default Loader;
