import { Fragment, useCallback } from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import ReportBaggageModal from 'components/ReportBaggageModal';
import { useBaggageContext } from 'context';
import { handleChangeModal } from 'context/actions';
import SASButton from 'SASButton';
import { config } from 'config';
import { redirectToSITA } from 'helpers/sita';


const Home = () => {
  const {
    state: {
      activeTab,
      texts: {
        'travel-ssdbr-heroHeading': headingText = '',
        'travel-ssdbr-label-reportDelayedButton': reportDelayed = '',
        'travel-ssdbr-label-statusButton': statusDelayed = '',
        'travel-ssdbr-label-reportDamagedButton': reportDamaged = '',
      } = {},
    },
    dispatch,
  } = useBaggageContext();

  const handleModal = useCallback(
    (open, target) => dispatch(handleChangeModal(open, target)),
    [dispatch]
  );

  return (
    <div>
      <ReportBaggageModal handleModal={handleModal} />
      <Grid item position="relative">
        <Box
          component="img"
          src={`https://images.ctfassets.net/oz9qk43ly6yk/1wVpALz4ppJa86j2g7y5SD/b7d80ccf8e1c69f12b58c31e2658e0e1/cover_home-min.png`}
          width="100%"
          sx={{
            height: {
              md: '680px',
              xs: '550px',
            },
          }}
        />
        <Grid
          container
          flexDirection="column"
          position="absolute"
          zIndex={1}
          top="0"
        >
          <Typography
            variant="h1"
            color="primary"
            alignItems="center"
            textAlign="center"
            maxWidth="650px"
            m="0 auto 21px"
            pt="84px"
            sx={{
              pt: {
                md: '65px',
                xs: '40px',
              },
            }}
          >
            {headingText?.title}
          </Typography>
          <Typography
            variant="p"
            textAlign="center"
            alignItems="center"
            maxWidth="450px"
            sx={{
              m: {
                md: '0 auto 35px',
                xs: '0 auto 20px',
              },
            }}
            dangerouslySetInnerHTML={{ __html: headingText?.description }}
          />
          {/* <Button
            color="secondary"
            variant="contained"
            sx={{ maxWidth: '291px', m: '0 auto 28px', fontSize: '14px' }}
            onClick={() => handleModal(true, 'delayed')}
          >
            {reportDelayed?.title}
          </Button> */}
          <SASButton
            sx={{ maxWidth: '291px', m: '0 auto 28px', fontSize: '14px' }} onClick={redirectToSITA}> {reportDelayed?.title}</SASButton>
          {/* <Button
            color="secondary"
            variant="contained"
            sx={{ maxWidth: '291px', m: '0 auto 28px', fontSize: '14px' }}
            onClick={() =>
              (window.location.href = 'http://www.worldtracer.aero/filedsp/sk.htm')
            }
          >
            {statusDelayed?.title}
          </Button> */}
          <SASButton
            sx={{ maxWidth: '291px', m: '0 auto 28px', fontSize: '14px' }}
            onClick={() =>
              (window.location.href = 'http://flysas.com/wt')
            }>{statusDelayed?.title}</SASButton>
          {/* <Button
            color="secondary"
            variant="contained"
            sx={{ maxWidth: '291px', m: '0 auto 28px', fontSize: '14px' }}
            onClick={() => handleModal(true, 'damaged')}
          >
            {reportDamaged?.title}
          </Button> */}
          <SASButton
            sx={{ maxWidth: '291px', m: '0 auto 28px', fontSize: '14px' }}
            onClick={redirectToSITA}>{reportDamaged?.title}</SASButton>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
