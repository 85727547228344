/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';
import { DELIVERY_OPTIONS } from './constant';
const specialsWithoutPlus = [
  '~',
  '`',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '_',
  '-',
  '=',
  '{',
  '}',
  '[',
  ']',
  '|',
  '\\',
  '/',
  ':',
  ';',
  '"',
  "'",
  '<',
  '>',
  ',',
  '.',
  '?',
];



export const scheduleSearchSchema = ({ pnr, lastName, informativeTexts }) =>
  Yup.object({
    pnr: Yup.string('Enter your booking reference')
      .min(
        pnr?.min || 5,
        informativeTexts?.minPnrError?.title || 'Booking reference should be of minimum ${min} characters length'
      )
      .max(
        pnr?.max || 6,
        informativeTexts?.maxPnrError?.title || 'Booking reference should be of maximum ${max} characters length'
      )
      .required(informativeTexts?.noPnrError?.title || 'Booking reference is required'),
    lastName: Yup.string('Enter your last name')
      .min(
        lastName?.min || 2,
        informativeTexts?.minLastNameError?.title || 'Last name should be of minimum ${min} characters length'
      )
      .max(
        lastName?.max || 16,
        informativeTexts?.maxLastNameError?.title || 'Last name should be of maximum ${max} characters length'
      )
      .required(informativeTexts?.noLastNameError?.title || 'Last name is required'),
  });

export const charterSearchSchema = (informativeTexts) =>
  Yup.object({
    bagtagNumber: Yup.string('Enter your bag tag')
      .trim()
      .matches(bagtagRegex, "Bagtag number is not valid")
      .required('Bagtag is required'),
    flightNumber: Yup.string('Enter flight number')
      .trim()
      .matches(flightNumRegex, 'Flight number is not in correct format')
      .required('Flight number is required'),
    date: Yup.string('Enter flight date').required(informativeTexts?.noDateError?.title || 'Date is required'),
  });

export const boundDetailsSchema = () =>
  Yup.object({
    origin: Yup.string('Enter flight origin city').required(
      'Origin point is required'
    ),
    destination: Yup.string('Enter flight destination city').required(
      'Destination point is required'
    ),
  });

const bagDescRegex = /^[ A-Za-z0-9,]+$/

const brandRegex = /^[ A-Za-z0-9]+$/

export const baggageDetailsSchema = (informativeTexts, values = {}) =>
  Yup.object({
    bagType: Yup.string('Select your bag type').required(
      informativeTexts?.bagTypeRequired?.title || 'Bag type is required'
    ),
    bagColor: Yup.string('Select your bag color').required(
      informativeTexts?.bagColorRequired?.title || 'Bag color is required'
    ),
    brand: Yup.string('Enter brand')
      // .matches(brandRegex, 'Special characters are not allowed')
      // .min(3, "Brand should be of atleast ${min} characters")
      .required(informativeTexts?.noBrand?.title || "Brand is required"),
    description: Yup.string('Enter your description')
      .matches(bagDescRegex, 'Special characters are not allowed')
      .min(values?.baggageDescription?.min || 0)
      .max(values?.baggageDescription?.max || 34, 'Description should be of maximum 34 characters length')
      .required(informativeTexts?.noDescription?.title || "Description is required"),
  });

const flightNumRegex = /([A-Z][\d]|[\d][A-Z]|[A-Z]{2})(\d{1,})$/gi  ///^[A-Za-z0-9]{1,2}[ ]*[0-9]{1,4}$/;

export const itineraryForm = (informativeTexts) =>
  Yup.object().shape({
    flights: Yup.array().of(
      Yup.object()
        .shape({
          originCity: Yup.string('Enter flight origin city').required(
            'Origin point is required'
          ),
          destinationCity: Yup.string('Enter flight destination city').required(
            'Destination point is required'
          ),
          flightDate:
            Yup.string('Enter flight date').required(informativeTexts?.noDateError?.title || 'Date is required'),
          flightNumber: Yup.string('Enter flight number')
            .trim()
            .matches(flightNumRegex, 'Flight number is not in correct format')
            .required('Flight number is required'),
        })
        .required('Must have friends')
    ),
  });

const bagtagRegex = /^\d{10}$/;
const ticketRegex = /^\d{3}\-\d{10}$/;
export const baggageForm = () =>
  Yup.object().shape({
    bags: Yup.array().of(
      Yup.object()
        .shape({
          number: Yup.string('Enter your baggage tag')
            .trim()
            .matches(bagtagRegex, "Bagtag number is not valid")
            .required('Baggage tag is required'),
        })
        .required('Baggage tag is required')
    ),
  });

const cityRegex = /^[^,.><:;'"-()[\]{}!_\-=\/*|~+&^%$`#@!0-9]*$/;

const ebNumberRegex = /^[a-zA-Z]{3}\d{9}$/;

export const contactInformationSchema = (
  values = {} /* { firstName, lastName, email, phoneNumber, permanentAddress: { city, address, postalCode } = {} } */,
  deliveryTo = DELIVERY_OPTIONS.PERMANENT_ADDRESS,
  notifyBy = 'EMAIL',
  searchType
) => {
  const isDeliverToPM = deliveryTo === DELIVERY_OPTIONS.PERMANENT_ADDRESS;
  return Yup.object().shape({
    firstName: Yup.string()
      .min(
        values?.firstName?.min || 2,
        'First name should be of minimum ${min} characters length'
      )
      .max(
        values?.firstName?.max || 16,
        'First name should be of maximum ${max} characters length'
      )
      .required(),
    lastName: Yup.string()
      .min(
        values?.lastName?.min || 2,
        values?.informativeTexts?.minLastNameError?.title || 'Last name should be of minimum ${min} characters length'
      )
      .max(
        values?.lastName?.max || 16,
        values?.informativeTexts?.maxLastNameError?.title || 'Last name should be of maximum ${max} characters length'
      )
      .required(),
    email: ['SMS_AND_EMAIL', 'EMAIL'].includes(notifyBy)
      ? Yup.string()
        .email()
        .min(values?.email?.min || 6)
        .max(values?.email?.max || 58)
        .required()
      : Yup.string(),
    phoneNumber: ['SMS_AND_EMAIL', 'SMS'].includes(notifyBy)
      ? Yup.string()
        .matches(/(^[+]\d+)$/)
        .min(values?.phoneNumber?.min || 8)
        .max(values?.phoneNumber?.max || 20)
        .test('phone', 'Enter a valid phone', function (value) {
          if (!value) return false;
          for (const symbol of specialsWithoutPlus) {
            if (value.includes(symbol)) return false;
          }
          return true;
        })
        .required()
      : Yup.string(),
    ticketNumber: searchType === 'charter'
      ? Yup.string()
      : Yup.string()
        .length(14, 'Ticket number must be of 14 characters')
        .matches(ticketRegex)
        .required('Ticket number is required'),
    ebNumber:
      Yup.string().length(12, 'Euro bonus number must be of length 12')
        .matches(ebNumberRegex, "Euro bonus number is not in correct format"),
    permanentAddress: Yup.object().shape({
      city: Yup.string()
        .min(values['permanentAddress.city']?.min || 1)
        .max(values['permanentAddress.city']?.max || 27)
        .matches(cityRegex, 'No special characters like ,.- are allowed')
        .required('City is required'),
      address: Yup.string()
        .min(values['permanentAddress.address']?.min || 0)
        .max(values['permanentAddress.address']?.max || 58)
        .required(),
      countryName: Yup.object().required(),
      postalCode: Yup.string()
        .min(values['permanentAddress.postalCode']?.min || 1)
        .max(values['permanentAddress.postalCode']?.max || 12)
        .required(),
    }),
    deliveryAddress: Yup.array().of(
      Yup.object()
        .shape({
          city:
            isDeliverToPM
              ? Yup.string()
              : Yup.string()
                .min(values['permanentAddress.city']?.min || 1)
                .max(values['permanentAddress.city']?.max || 27)
                .matches(cityRegex, 'No special characters like ,.- are allowed')
                .required(),
          address:
            isDeliverToPM
              ? Yup.string()
              : Yup.string()
                .min(values['permanentAddress.address']?.min || 0)
                .max(values['permanentAddress.address']?.max || 58)
                .required(),
          countryName:
            isDeliverToPM
              ? Yup.object()
              : Yup.object().required(),
          postalCode:
            isDeliverToPM
              ? Yup.string()
              : Yup.string()
                .min(values['permanentAddress.postalCode']?.min || 1)
                .max(values['permanentAddress.postalCode']?.max || 12)
                .required(),
          co:
            isDeliverToPM
              ? Yup.string()
              : Yup.string().required(),
          validUntil: 
            isDeliverToPM
              ? Yup.string().nullable(true)
              : Yup.string().required(),
        })
        .required('Delivery address required')
    ),
  })
};