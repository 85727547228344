import { Box, Modal as MuiModal, Paper } from '@mui/material';

const Modal = ({ open = true, children, handleModal, style }) => {
  const handleCloseModal = () => handleModal(false, '');

  return (
    <MuiModal
      sx={{
        p: {
          sm: '0 24px',
          xs: '0 10px',
        },
        m: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={handleCloseModal}
      style={style}
    >
      <Paper
        sx={{
          p: '20px',
          maxWidth: '720px',
          width: '100%',
          overflow: { sm: 'hidden', xs: 'scroll' },
          height: 'fit-content',
          maxHeight: 'calc(100vh - 40px)',
        }}
      >
        <Box
          sx={{
            cursor: 'pointer',
            width: 'fit-content',
            ml: 'auto',
          }}
          onClick={handleCloseModal}
        >
          <s4s-icon name="in--close" />
        </Box>
        {children}
      </Paper>
    </MuiModal>
  );
};

export default Modal;
