import { Fragment } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Loader from 'components/Loader';
import { useBaggageContext } from 'context';

const Layout = ({ children }) => {
  const {
    state: { getTranslationsStatus },
  } = useBaggageContext();

  return (
    <div>
      <Loader open={getTranslationsStatus === 'loading'} />
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
