async function client(url, method, body) {
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Charset': 'utf-8',
    },
    method,
    body: JSON.stringify(body),
  });
  if (response?.status === 204) return response;
  else {
    const data = await response.json();
    return { ...data, status: response.status };
  }
}

export default client;
