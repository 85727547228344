export const HANDLE_MODAL = 'HANDLE_MODAL';
export const HANDLE_HEADER_TAB = 'HANDLE_HEADER_TAB';
export const HANDLE_CHANGE_STATE = 'HANDLE_CHANGE_STATE';
export const HANDLE_CHANGE_BOOKING_FLIGHTS = 'HANDLE_CHANGE_BOOKING_FLIGHTS';
export const HANDLE_CHANGE_BOOKING_BAGGAGE = 'HANDLE_CHANGE_BOOKING_BAGGAGE';
export const HANDLE_CHANGE_BAGGAGE_DETAILS = 'HANDLE_CHANGE_BAGGAGE_DETAILS';
export const HANDLE_CHANGE_EXPAND_SECTIONS = 'HANDLE_CHANGE_EXPAND_SECTIONS';
export const GET_BOOKING_SUCCESS = 'GET_BOOKING_SUCCESS';
export const GET_BOOKING_LOADING = 'GET_BOOKING_LOADING';
export const GET_BOOKING_ERROR = 'GET_BOOKING_ERROR';
export const GET_TRANSLATIONS_SUCCESS = 'GET_TRANSLATIONS_SUCCESS';
export const GET_TRANSLATIONS_ERROR = 'GET_TRANSLATIONS_ERROR';
export const GET_TRANSLATIONS_LOADING = 'GET_TRANSLATIONS_LOADING';
export const CONFIRM_REPORT_SUCCESS = 'CONFIRM_REPORT_SUCCESS';
export const CONFIRM_REPORT_ERROR = 'CONFIRM_REPORT_ERROR';
export const CONFIRM_REPORT_LOADING = 'CONFIRM_REPORT_LOADING';
export const RESET_CONTEXT = "RESET_CONTEXT";
export const HANDLE_CHANGE_BAGGAGE_STATUS = "HANDLE_CHANGE_BAGGAGE_STATUS";
export const HANDLE_CHANGE_CUSTOMS_DECLARATION = "HANDLE_CHANGE_CUSTOMS_DECLARATION"