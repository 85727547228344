import React from 'react';
import { Helmet } from 'react-helmet';
import { config } from '../../config';

function SASElements({ title, description }) {
  const basePath = config.isTEST
    ? window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
      ? 'https://test.sas.se'
      : ''
    : 'https://www.flysas.com';

  window.s4sBaseSrc = basePath + '/v2';

  return (
    <>
      <Helmet>
        {config.isTEST && <meta name="robots" content="noindex"></meta>}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${basePath}/appdata/webclips/apple-touch-icon.png?v=1`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${basePath}/appdata/webclips/favicon-32x32.png?v=1`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${basePath}/appdata/webclips/favicon-16x16.png?v=1`}
        />
        <link
          rel="manifest"
          href={`${basePath}/appdata/webclips/site.webmanifest?v=1`}
        />
        <link
          rel="mask-icon"
          href={`${basePath}/appdata/webclips/safari-pinned-tab.svg?v=1`}
          color="#000099"
        />
        <link rel="shortcut icon" href={`${basePath}/favicon.ico?v=1`} />

        <link
          rel="stylesheet"
          type="text/css"
          href={`${basePath}/v2/cms-s4s-elements/build.css`}
        />
        {/* <link
          rel="stylesheet"
          type="text/css"
          href={`${basePath}/v2/prof-profile-ui/s4s-login/build.css`}
        /> */}
        <script
          src={`${basePath}/v2/cms-s4s-elements/s4s-loader-rev.js`}
          async
        ></script>
        {/* <script
          src={`${basePath}/v2/cms-s4s-elements/s4s-bundle.js`}
          async
        ></script> */}
        <script
          src={`${basePath}/v2/cms-s4s-elements/partials/icons/icons.js`}
          async
        ></script>
        {/* <script src="https://www.googleoptimize.com/optimize.js?id=GTM-N4GLXGX"></script> */}
        {/* <script
          src={`${basePath}/v2/prof-profile-ui/s4s-prof-base/build.js`}
          async
        ></script> */}
        {/* <script
          src={`${basePath}/v2/prof-profile-ui/s4s-login/build.js`}
          async
        ></script> */}
      </Helmet>
      {/* <s4s-login hidden style={{ top: '74px' }}></s4s-login> */}
      {/* <s4s-overlay hidden></s4s-overlay> */}
    </>
  );
}

export default SASElements;
