import { useMemo, useCallback, useState } from 'react';
import { Typography, Grid, Box, Autocomplete, TextField, Popper } from '@mui/material';
import { useFormik } from 'formik';
import SectionWrapper from 'components/SectionWrapper';
import Input from 'components/Input';
import BaggageColorSelect from './components/BaggageColorSelect';
import BaggageTypeSelect from './components/BaggageTypeSelect';
import { useBaggageContext } from 'context';
import { handleBaggageDetails, handleChangeState } from 'context/actions';
import { baggageDetailsSchema } from 'helpers/validationSchema';
import SASButton from 'SASButton';
import { CUSTOM_DECLARATION_AIRPORTS, FRANCE_DESTINATION } from 'helpers/constant';
import { config } from 'config';

const BaggageDetails = ({
  checkedBag,
  nextBag,
  index,
  handleExpandSections,
  setShowCustomDeclaration
}) => {
  const [bagType, setBagType] = useState({ code: '', title: '' });
  const [bagColor, setBagColor] = useState({ code: '', title: '' });
  const {
    state: {
      flights,
      baggageDetails,
      expandSections,
      texts: {
        'travel-ssdbr-label-explainBag': title = '',
        'travel-ssdbr-label-bag': bagNumberTitle = '',
        'travel-ssdbr-label-continueButton': continueButton = '',
        'travel-ssdbr-label-reset': resetButton = '',
        'travel-ssdbr-label-selectBagType': selectBagType = '',
        'travel-ssdbr-label-selectColor': selectBagColor = '',
        'travel-ssdbr-label-descriptionAndBrand': descAndBrand = '',
        'travel-ssdbr-label-brand': brandLabel = '',
        'travel-ssdbr-label-description': descriptionLabel = '',
        'travel-ssdbr-label-error-bagTypeRequired': bagTypeRequired = '',
        'travel-ssdbr-label-error-bagColorRequired': bagColorRequired = '',
        'travel-ssdbr-label-noBrand': noBrand = '',
        'travel-ssdbr-label-noDescription': noDescription = '',
        'travel-ssdbr-brandOptionsList': brandOptions = [],
      } = {},
    },
    dispatch,
  } = useBaggageContext();

  const CustomPopper = (props) => { return ( <Popper {...props} placement="bottom" sx={{ height: "10px", }} style={{ width: props.anchorEl.clientWidth, height: "5px" }} /> ); }

  const bagStatus = useMemo(
    () => expandSections?.bagsDetails[checkedBag],
    [expandSections, checkedBag]
  );

  const validationValues = {
    baggageDescription: {
      min: 0,
      max: 58,
    }
  }

  const handleSubmit = useCallback(
    (brand, description) => {
      dispatch(
        handleBaggageDetails({
          [checkedBag]: {
            bagType,
            bagColor,
            brand,
            description,
          },
        })
      );
      const sections = {
        bagsDetails: {
          ...expandSections?.bagsDetails,
          [checkedBag]: { show: false, accepted: true },
        },
      };
      if (nextBag)
        sections.bagsDetails[nextBag] = { show: true, accepted: false };

      handleExpandSections(sections);
      document.getElementById(`baggage-details-${checkedBag}-section`)?.scrollIntoView({ behavior: 'smooth' })
      if (!nextBag) {
        const station = flights[flights.length - 1]?.arrival?.station;
        const expandSections = {};
        dispatch(handleChangeState({ visitedTab: 3 }));
        if (CUSTOM_DECLARATION_AIRPORTS.includes(station) || FRANCE_DESTINATION.includes(station) || config.isTEST) {
          handleExpandSections({
            customDeclaration: true,
          });
          setShowCustomDeclaration(true);
        }
        else {
          expandSections.contactInformation = true
        }
        setTimeout(() => {
          handleExpandSections({
            ...expandSections,

          });
        }, 500)
      }
    },
    [
      checkedBag,
      bagType,
      bagColor,
      dispatch,
      handleExpandSections,
      expandSections,
      nextBag,
      flights
    ]
  );

  const informativeTexts = {
    bagTypeRequired,
    bagColorRequired,
    noBrand,
    noDescription
  };

  const formik = useFormik({
    initialValues: {
      brand: baggageDetails[checkedBag]?.brand || '',
      description: baggageDetails[checkedBag]?.description || '',
      bagColor: baggageDetails[checkedBag]?.bagColor?.code || '',
      bagType: baggageDetails[checkedBag]?.bagType?.code || '',
    },
    initialTouched: {
      bagColor: Boolean(baggageDetails[checkedBag]?.bagColor?.code),
      bagType: Boolean(baggageDetails[checkedBag]?.bagType?.code),
    },
    enableReinitialize: true,
    validationSchema: baggageDetailsSchema(informativeTexts, validationValues),
    onSubmit: ({ brand, description }) => handleSubmit(brand, description),
  });

  const checkErrors = () => {
    let elementId = null;
    if (Object.keys(formik.errors).includes('bagType')) {
      elementId = `baggage-type-err-${checkedBag}`;
    }
    else if (Object.keys(formik.errors).includes('bagColor')) {
      elementId = `baggage-color-err-${checkedBag}`;
    }
    if (elementId)
      document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
  const handleContinue = useCallback(() => {
    checkErrors();
    formik?.setFieldTouched('bagType', true);
    formik?.setFieldTouched('bagColor', true);
    const button = document.getElementById(
      'hidden-button-submit_' + checkedBag
    );
    if (button) button.click();
  }, [checkedBag, formik]);

  const handleChangeBagType = useCallback(
    (value) => {
      setBagType(value);
      formik?.setFieldValue('bagType', value?.code);
    },
    [formik]
  );

  const handleChangeBagColor = useCallback(
    (value) => {
      setBagColor(value);
      formik?.setFieldValue('bagColor', value?.code);
    },
    [formik]
  );

  const resetDetails = useCallback(() => {
    formik.resetForm();
    handleChangeBagType({ code: '', title: '' });
    handleChangeBagColor({ code: '', title: '' });
    dispatch(
      handleBaggageDetails({
        [checkedBag]: {
          bagType: { code: '', title: '' },
          bagColor: { code: '', title: '' },
          brand: '',
          description: '',
        },
      })
    );
  }, [dispatch, checkedBag, formik, handleChangeBagColor, handleChangeBagType]);

  return (
    <SectionWrapper
      expand={bagStatus?.show && !bagStatus?.accepted}
      id={`baggage-details-${checkedBag}-section`}
      title={
        !bagStatus?.show && bagStatus?.accepted
          ? {
            bindings: {
              bagNr: index + 1,
            },
            jsx: bagNumberTitle?.title,
          }
          : { jsx: title?.title }
      }
      summaryText={
        expandSections?.bagsDetails[checkedBag]?.show
          ? `Luggage #${index + 1} - ${checkedBag}`
          : `${baggageDetails[checkedBag]?.bagType?.title}, ${baggageDetails[checkedBag]?.bagColor?.title}`
      }
      handleChange={() =>
        handleExpandSections({
          bagsDetails: {
            ...expandSections?.bagsDetails,
            [checkedBag]: { show: true, accepted: false },
          },
          contactInformation: false
        })
      }
      subtitle=""
      linkClick={() => resetDetails()}
      linkText={resetButton?.title}
      button={continueButton?.title}
      buttonClick={() => handleContinue()}
      style={{
        display:
          bagStatus?.show || (!bagStatus?.show && bagStatus?.accepted)
            ? 'inherit'
            : 'none',
      }}
    >
      <Grid container flexDirection="column">
        <Grid container flexDirection="column">
          <Typography
            variant="baggageList"
            sx={{ mt: { sm: '18px', xs: '0' } }}
            mb="18px"
          >
            {selectBagType?.title}
          </Typography>
          <BaggageTypeSelect
            checkedBag={checkedBag}
            name="bagType"
            formik={formik}
            handleChange={handleChangeBagType}
          />
        </Grid>
        <Grid
          container
          flexDirection="column"
          sx={{ mt: { sm: '48px', xs: '24px' } }}
        >
          <Typography variant="baggageList" mb="18px">
            {selectBagColor?.title}
          </Typography>
          <BaggageColorSelect
            checkedBag={checkedBag}
            name="bagColor"
            formik={formik}
            handleChange={handleChangeBagColor}
          />
        </Grid>
        <Grid
          container
          flexDirection="column"
          sx={{ mt: { sm: '48px', xs: '24px' } }}
        >
          <Typography variant="baggageList" mb="18px">
            {descAndBrand?.title}
          </Typography>
          <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column' }}
            onSubmit={formik?.handleSubmit}
          >
            <Grid
              container
              justifyContent="space-between"
              flexWrap="nowrap"
              sx={{
                flexDirection: {
                  sm: 'row',
                  xs: 'column',
                },
                alignItems: {
                  sm: 'initial',
                  xs: 'center',
                },
              }}
            >
              <Grid
                container
                sx={{ flexDirection: 'column' }}
              >
                <Autocomplete
                  disableClearable
                  forcePopupIcon={false}
                  options={brandOptions[0]}
                  PopperComponent={CustomPopper}
                  value={formik?.values?.brand}
                  sx={{
                    maxWidth: { sm: '381px', xs: '100%' },
                  }}
                  onChange={(e, v) => formik?.setFieldValue('brand', v)}
                  renderInput={(params) => (
                    <Input
                      name="brand"
                      label={brandLabel?.title}
                      handleChange={params?.inputProps?.onChange}
                      formik={formik}
                      params={{
                        ...params,
                      }}
                      style={{
                        maxWidth: { sm: '381px', xs: '100%' },
                      }}
                    // validvalues={validationValues?.baggageDescription}
                    />
                  )}
                />
                <Typography variant="caption" fontSize="12px" color="error">
                  {formik?.touched["brand"] &&
                    Boolean(formik?.errors["brand"]) &&
                    `${formik?.errors["brand"]}`}
                </Typography>
              </Grid>
              <Grid
                container
                sx={{ flexDirection: 'column' }}
              >
                <Input
                  name="description"
                  label={descriptionLabel?.title}
                  value={formik?.values?.description}
                  handleChange={formik?.handleChange}
                  formik={formik}
                  style={{
                    mr: '0',
                    maxWidth: '577px',
                  }}
                  validvalues={validationValues?.baggageDescription}
                />
                <Typography variant="caption" fontSize="12px" color="error">
                  {formik?.touched["description"] &&
                    Boolean(formik?.errors["description"]) &&
                    `${formik?.errors["description"]}`}
                </Typography>
              </Grid>
              <SASButton id={'hidden-button-submit_' + checkedBag}
                type="submit"
                sx={{ display: 'none' }}></SASButton>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </SectionWrapper>
  );
};

export default BaggageDetails;
