import moment from 'moment';

export const getBoundBaggage = (bound, baggageTags) => {
  const boundSegments = bound.segments?.map(({ id }) => id);
  const bagTags = baggageTags.filter((bag) =>
    bag.segmentIds.some((seg) => boundSegments.indexOf(seg) >= 0)
  );
  const uniqueBagTags = [...new Map(bagTags.map(bag =>
    [bag.number, bag])).values()];
  return uniqueBagTags;;
};

export const in7DaysWindow = (start) => {
  return moment(
    start
  ).add(7, 'days').isAfter(moment().utc());
}

// sk1 to SK0001
export const formatFlightNumber = (flightNumber) => {
  return `${flightNumber.substring(0, 2).toUpperCase()}${flightNumber.substring(2).padStart(4, "0")}`;;
}

export const mapFlight = flightStatuses => (
  {
    arrival: {
      station: flightStatuses?.arrivalAirportFsCode,
      scheduledTime: {
        utc: flightStatuses?.arrivalDate?.dateUtc,
        local: flightStatuses?.arrivalDate?.dateLocal,
      },
      terminal: '',
    },
    departure: {
      station: flightStatuses?.departureAirportFsCode,
      scheduledTime: {
        utc: flightStatuses?.departureDate?.dateUtc,
        local: flightStatuses?.departureDate?.dateLocal,
      },
      terminal: '',
    },
    id: flightStatuses?.flightId,
    marketingCarrier: flightStatuses?.carrierFsCode,
    marketingFlightNumber: flightStatuses?.flightNumber,
    operatingCarrier: flightStatuses?.carrierFsCode,
    operatingFlightNumber: flightStatuses?.flightNumber,
    operatingId: flightStatuses?.flightId,
  }
)