import { Button, Grid, Typography } from '@mui/material';
import { useBaggageContext } from 'context';
import { handleChangeModal, handleChangeState } from 'context/actions';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';
import ContactsList from 'components/ContactsList';
import { useBackListener } from 'hooks/useBackListener'
import SASButton from 'SASButton';

const Confirmed = () => {
  const navigate = useNavigate();
  const {
    state: {
      report,
      modal,
      texts,
      texts: {
        'travel-ssdbr-label-referenceNr': referenceTitle = '',
        'travel-ssdbr-label-makeAnotherReportButton': makeAnotherButton = '',
        'travel-ssdbr-label-contactInformation': contactTitle = '',
        'travel-ssdbr-label-seeContactInfoButton': seeContact = '',
        'travel-ssdbr-contactNumbersList': contactNumbers,
        'travel-ssdbr-label-claim-note': claimNote = '', 
      } = {},
    },
    dispatch,
  } = useBaggageContext();

  const contact = useMemo(
    () => {
      if(contactNumbers){
        const contacts = contactNumbers[0]
        return contacts?.find((item) => item?.code === report?.stationCode)}
      },
    [report?.stationCode, contactNumbers]
  );

  const handleModal = useCallback(
    (open, target) => dispatch(handleChangeModal(open, target)),
    [dispatch]
  );

  useBackListener(({ location }) =>
    window.location.href = "/travel/bags/"
  );

  return (
    <Grid item maxWidth="1052px" width="100%" m="auto">
      <Modal
        open={modal?.open && modal?.target === 'confirmedContacts'}
        handleModal={handleModal}
      >
        <Grid container flexDirection="column">
          <Typography
            variant="h6"
            color="primary"
            sx={{ fontSize: '18px', mb: '35px' }}
          >
            {texts['travel-ssdbr-airportsContactInfo']?.title}
          </Typography>
          <Typography
            variant="p"
            dangerouslySetInnerHTML={{
              __html: texts['travel-ssdbr-airportsContactInfo']?.description,
            }}
          />
          <br />
          <ContactsList />
          <br />
          <SASButton
            sx={{
              maxWidth: 'fit-content',
              fontSize: '14px',
              m: '25px 0 0 auto',
            }}
            onClick={() => handleModal(false, '')}>
            {texts['travel-ssdbr-label-closeButton']?.title}
          </SASButton>
        </Grid>
      </Modal>
      <Grid
        container
        border="1px solid rgba(0, 0, 0, 0.2)"
        mt="45px"
        justifyContent="space-between"
        sx={{
          p: { sm: '28px', xs: '16px' },
        }}
      >
        <Typography variant="h5" color="primary">
          {referenceTitle?.title}
        </Typography>
        <Typography variant="h1" color="primary">
          {report?.reportReference || 0}
        </Typography>
      </Grid>
      <Grid
        container
        border="1px solid rgba(0, 0, 0, 0.2)"
        mt="45px"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: { sm: '28px', xs: '16px' },
        }}
      >
        <Grid item xs={12} sm={8} display="flex" flexDirection="column">
          <Typography variant="h5" color="primary" mb="15px">
            {contactTitle?.title}
          </Typography>
          <Typography variant="p">
            {`${contact?.city} ${contact?.code} ${contact?.phone}`}
          </Typography>
        </Grid>
        <Grid>
          {/* <Button
            color="secondary"
            variant="outlined"
            onClick={() => handleModal(true, 'confirmedContacts')}
          >
            {seeContact.title}
          </Button> */}
          <SASButton
            onClick={() => handleModal(true, 'confirmedContacts')}>   {seeContact.title}</SASButton>
        </Grid>
      </Grid>
      <Typography p='4px' mt='20px' dangerouslySetInnerHTML ={{
        __html: claimNote?.title
      }}  />
      <Grid
        container
        sx={{ p: { sm: '0', xs: '16px' }, m: { sm: '75px 0', xs: '30px 0' } }}
      >
        {/* <Button
          variant="contained"
          color="secondary"
          sx={{ width: { sm: 'fit-content', xs: '100%' }, ml: 'auto' }}
          onClick={() =>
            (window.location.href = '/travel/bags/')
          }
        >
          {makeAnotherButton?.title}
        </Button> */}
        <SASButton
          sx={{ width: { sm: 'fit-content', xs: '100%' }, ml: 'auto' }}
          onClick={() =>
            (window.location.href = '/travel/bags/')
          }>
          {makeAnotherButton?.title}
        </SASButton>
      </Grid>
    </Grid>
  );
};

export default Confirmed;
