import { Grid, Typography } from '@mui/material';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Fragment } from 'react';
import Input from 'components/Input';
import { useBaggageContext } from 'context';
import { baggageForm } from 'helpers/validationSchema';
import SASButton from 'SASButton';

const AddBaggageModal = ({ onSubmit }) => {
  const {
    state: {
      texts: {
        'travel-ssdbr-label-manualBagtag': title,
        'travel-ssdbr-label-BagtagNumber': inputLabel,
        'travel-ssdbr-label-addBagtag': addTag,
        'travel-ssdbr-label-continueButton': button,
        'travel-ssdbr-label-removeTempAddress': removeAddress = {
          title: 'Remove',
        },
        'travel-ssdbr-manualBagtagText': manualBagtagText
      } = {},
    },
  } = useBaggageContext();


  return (
    <Grid
      container
      flexDirection="column"
      sx={{ p: { xs: '0', sm: '0 24px 24px' } }}
    >
      <Typography
        variant="h6"
        color="primary"
        sx={{ fontSize: '18px', mb: '9px' }}
      >
        {title?.title}
      </Typography>
      <Typography variant="p" sx={{ mb: '26px' }}>
        {manualBagtagText?.title}
      </Typography>
      <Formik
        initialValues={{
          bags: [
            {
              number: '',
            },
          ],
        }}
        validationSchema={baggageForm()}
        onSubmit={(values) => onSubmit(values)}
        render={({ values, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name="bags"
              render={(arrayHelpers) => (
                <div>
                  {values.bags.map((_, index, array) => (
                    <Grid item key={index} mb="10px">
                      <Field name={`bags[${index}].number`}>
                        {({ field, meta }) => (
                          <Input
                            name={`bags[${index}].number`}
                            label={inputLabel?.title}
                            value={values?.bags[index]?.number}
                            handleChange={field?.onChange}
                            formik={{
                              errors: {
                                [field?.name]: meta?.error,
                              },
                              touched: {
                                [field?.name]: meta?.touched,
                              },
                            }}
                            style={{
                              maxWidth: { sm: '296px', xs: 'auto' },
                            }}
                            validvalues={{
                              min: 10,
                              max: 10,
                            }}
                          />
                        )}
                      </Field>
                      <Grid container justifyContent="space-between">
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            cursor: 'pointer',
                            display:
                              array?.length - 1 === index ? 'initial' : 'none',
                          }}
                          onClick={() =>
                            arrayHelpers.push({
                              number: '',
                            })
                          }
                        >
                          {addTag?.title}
                        </Typography>
                        <Typography
                          textAlign="center"
                          onClick={() => arrayHelpers.remove(index)}
                          color="secondary"
                          sx={{
                            cursor: 'pointer',
                            ml: 'auto',
                            display: array?.length === 1 ? 'none' : 'initial',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: removeAddress?.title,
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </div>
              )}
            />
            <Grid container>
              {/* <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ width: 'auto', fontSize: '14px', m: '25px 0 0 auto' }}
              >
                {button?.title}
              </Button> */}
              <SASButton type="submit"
                sx={{ width: 'auto', fontSize: '14px', m: '25px 0 0 auto' }}> {button?.title}</SASButton>
            </Grid>
          </Form>
        )}
      />
    </Grid>
  );
};

export default AddBaggageModal;
