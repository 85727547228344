import { Typography, Grid } from '@mui/material';
import Input from 'components/Input';
import { useCallback } from 'react';
import { useBaggageContext } from 'context';
import CountryAutocomplete from 'components/CountryAutocomplete';
import AddressAutocomplete from 'components/AddressAutocomplete';

const PermanentAddress = ({ formik, inputStyle, validationValues }) => {
  const {
    state: {
      texts: {
        'travel-ssdbr-label-heading-permanentAddress': title = '',
        'travel-ssdbr-label-address': addressLabel = '',
        'travel-ssdbr-label-postalcode': postalcodeLabel = '',
        'travel-ssdbr-label-city': cityLabel = '',
        'travel-ssdbr-label-country': countryLabel = '',
      } = {},
    },
  } = useBaggageContext();

  const getFormikErrors = useCallback(
    (name) => {
      const [objectName, property] = name.split('.');
      const value = {
        errors: {},
        touched: {},
      };

      if (formik.errors[objectName] && formik.touched[objectName]) {
        value.errors[name] = formik?.errors?.[objectName][property];
        value.touched[name] = formik?.touched?.[objectName][property];
      }

      return value;
    },
    [formik]
  );

  return (
    <Grid container flexDirection="column" id="permanent-address-form">
      <Typography
        variant="baggageList"
        sx={{ m: { sm: '48px 0 12px 0', xs: '24px 0 12px 0' } }}
        dangerouslySetInnerHTML={{ __html: title?.title }}
      />
      <Grid
        container
        flexWrap="nowrap"
        sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
      >
      < Input
          value={formik?.values?.permanentAddress?.address}
          handleChange={formik?.handleChange}
          label={addressLabel?.title}
          name="permanentAddress.address"
          style={inputStyle}
          formik={getFormikErrors('permanentAddress.address')}
          validvalues={validationValues['permanentAddress.address']}
        />
        <Input
          name="permanentAddress.postalCode"
          label={postalcodeLabel?.title}
          value={formik?.values?.permanentAddress?.postalCode}
          handleChange={formik?.handleChange}
          formik={getFormikErrors('permanentAddress.postalCode')}
          style={inputStyle}
          validvalues={validationValues['permanentAddress.postalCode']}
        />
      </Grid>
      <Grid
        container
        flexWrap="nowrap"
        width='inherit'
        sx={{ flexDirection: { sm: 'row', xs: 'column' }}}
      >
         <Grid
              container
              
              marginRight = {{ sm: '10px'}}
              sx = {{ flexDirection: 'column', maxWidth: {xs: 'auto', sm: '313px'}}}
              >
          <Input
            name="permanentAddress.city"
            label={cityLabel?.title}
            value={formik?.values?.permanentAddress?.city}
            handleChange={formik?.handleChange}
            formik={getFormikErrors('permanentAddress.city')}
            style={inputStyle}
            validvalues={validationValues['permanentAddress.city']}
          />
          
          <Typography variant="caption" fontSize="12px" color="error" sx={{...inputStyle}} pb='10px'>
            {formik?.touched?.permanentAddress?.city &&
            Boolean(formik?.errors?.permanentAddress?.city) &&
            `${formik?.errors?.permanentAddress?.city}`}
          </Typography>
        </Grid>
        <CountryAutocomplete
          name="permanentAddress.countryName"
          label={countryLabel?.title}
          value={formik?.values?.permanentAddress?.countryName}
          handleChange={formik?.setFieldValue}
          formik={getFormikErrors('permanentAddress.countryName')}
          style={inputStyle}
        />
      </Grid>
    </Grid>
  );
};

export default PermanentAddress;
