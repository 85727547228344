import { Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import Input from 'components/Input';
import { useBaggageContext } from 'context';

const ContactsList = () => {
  const {
    state: {
      texts: {
        'travel-ssdbr-contactNumbersList': contactNumbers
      } = {}
    }
  } = useBaggageContext();

  const [search, setSearch] = useState('');

  const contactsList = useMemo(
    () => {
      if (contactNumbers) {
        const contacts = contactNumbers[0]
        return contacts?.filter(
          (contact) =>
            contact?.city?.toLowerCase()?.startsWith(search?.toLowerCase()) ||
            contact?.code?.toLowerCase()?.startsWith(search?.toLowerCase())
        ) || []
      }

    },

    [search, contactNumbers]
  );

  return (
    <Grid container flexDirection="column">
      <Input
        name="Search"
        label="Search"
        value={search}
        handleChange={(e) => setSearch(e.target.value)}
        style={{
          maxWidth: '100%',
          mb: '30px',
        }}
      />
      <Grid
        container
        flexDirection="column"
        flexWrap="nowrap"
        maxHeight="182px"
        overflow="scroll"
      >
        {contactsList?.length > 0
          ? contactsList?.map((contact, index) => (
            <Grid
              container
              key={index}
              justifyContent="space-between"
              p="10px 14px"
              bgcolor={index % 2 === 0 ? '#f4f4f4' : '#ffffff'}
            >
              <Typography variant="p">
                {contact?.city + ' - ' + contact?.code}
              </Typography>
              <br />
              {/* <Typography variant="p">{contact?.phone}</Typography> */}
              {contact.phone && <Typography variant='p' >
                PHONE: {contact?.phone?.split(';').map((phone) => (<a style={{ textDecoration: 'none' }} href={"tel:" + phone?.trim()}>{phone?.trim()} </a>))}
              </Typography> 
              }

              {contact.email && <Typography variant='p' >
                EMAIL: <a style={{ textDecoration: 'none' }} href={"mailto:" + contact.email}>{contact.email}</a>
              </Typography>
              }
            </Grid>
          ))
          : 'No search match'}
      </Grid>
    </Grid>
  );
};

export default ContactsList;
