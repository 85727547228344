import client from '.';
import { config } from 'config';

const lang = {
  se: 'se-sv',
  no: 'no-no',
  dk: 'dk-da',
};

export const getTranslation = async () => {
  let api =
    config.D360_BASE_URL + '/v2/cms-www/fragment/travel-ssdbr/?format=raw';
  const domain = window.location.origin.slice(-2);
  const market = lang[domain];
  if (market) api += `&market=${market}`;

  return await client(api, 'get');
};
