import { useContext } from 'react';
import { createContext, useReducer } from 'react';
import {
  HANDLE_MODAL,
  HANDLE_HEADER_TAB,
  HANDLE_CHANGE_STATE,
  HANDLE_CHANGE_BOOKING_BAGGAGE,
  HANDLE_CHANGE_BOOKING_FLIGHTS,
  HANDLE_CHANGE_BAGGAGE_DETAILS,
  GET_BOOKING_ERROR,
  GET_BOOKING_LOADING,
  GET_BOOKING_SUCCESS,
  GET_TRANSLATIONS_ERROR,
  GET_TRANSLATIONS_LOADING,
  GET_TRANSLATIONS_SUCCESS,
  CONFIRM_REPORT_ERROR,
  CONFIRM_REPORT_LOADING,
  CONFIRM_REPORT_SUCCESS,
  HANDLE_CHANGE_EXPAND_SECTIONS,
  HANDLE_CHANGE_BAGGAGE_STATUS,
  RESET_CONTEXT,
HANDLE_CHANGE_CUSTOMS_DECLARATION
} from './constants';
import moment from 'moment';

const initialState = {
  searchType: '',
  pnr: '',
  lastName: '',
  bagTagNumber: '',
  flightNumber: '',
  departureDate: '',
  modal: {
    target: '',
    open: false,
  },
  originBound: '',
  destinationBound: '',
  flights: [],
  baggage: [],
  passenger: null,
  bagTags: [],
  searchTab: 0,
  baggageDetails: {},
  activeTab: 0,
  visitedTab: 0,
  transactionId: '',
  expandSections: {
    flights: false,
    baggage: false,
    bagsDetails: {},
    contactInformation: false,
    bound: true,
    customDeclaration: false
  },
  notifyBy: '',
  deliveryTo: '',
  contacts: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '+',
    permanentAddress: {
      city: '',
      address: '',
      countryName: '',
      postalCode: '',
    },
    deliveryAddress: [
      {
        city: '',
        address: '',
        countryName: '',
        postalCode: '',
        co: '',
        validUntil: null,
      },
    ],
  },
  getBookingStatus: '',
  getTranslationsStatus: '',
  getBagtagStatus: '',
  confirmReportStatus: '',
  manuallyAddedFlights: false,
  isGroupBooking: false,
  customDeclaration: '',
  ebNumber: ''
};

const BaggageContext = createContext({
  state: initialState,
  dispatch: () => { },
  displayName: 'BaggageContext',
});

function reducer(state, { type, payload }) {
  switch (type) {
    case HANDLE_CHANGE_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    case HANDLE_CHANGE_EXPAND_SECTIONS: {
      return {
        ...state,
        expandSections: {
          ...state.expandSections,
          ...payload,
        },
      };
    }
    case HANDLE_MODAL: {
      return {
        ...state,
        modal: {
          ...state.modal,
          ...payload,
        },
      };
    }
    case HANDLE_HEADER_TAB: {
      return {
        ...state,
        activeTab: payload,
      };
    }
    case HANDLE_CHANGE_BOOKING_BAGGAGE: {
      return {
        ...state,
        bagTags: [...state.bagTags, ...payload],
        modal: {
          open: false,
          target: '',
        },
      };
    }
    case HANDLE_CHANGE_BAGGAGE_STATUS: {
      return {
        ...state,
        bagTags: [...state.bagTags, ...payload],
      };
    }
    case HANDLE_CHANGE_BOOKING_FLIGHTS: {
      return {
        ...state,
        flights: payload,
        modal: {
          open: false,
          target: '',
        },
      };
    }
    case HANDLE_CHANGE_BAGGAGE_DETAILS: {
      return {
        ...state,
        baggageDetails: {
          ...state.baggageDetails,
          ...payload,
        },
      };
    }
    case GET_BOOKING_SUCCESS: {
      return {
        ...state,
        ...payload,
        getBookingStatus: 'success',
      };
    }
    case GET_BOOKING_LOADING: {
      return {
        ...state,
        getBookingStatus: 'loading',
      };
    }
    case GET_BOOKING_ERROR: {
      return {
        ...state,
        getBookingStatus: 'error',
      };
    }
    case GET_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        ...payload,
        getTranslationsStatus: 'success',
      };
    }
    case GET_TRANSLATIONS_LOADING: {
      return {
        ...state,
        getTranslationsStatus: 'loading',
      };
    }
    case GET_TRANSLATIONS_ERROR: {
      return {
        ...state,
        getTranslationsStatus: 'error',
      };
    }
    case CONFIRM_REPORT_SUCCESS: {
      return {
        ...state,
        confirmReportStatus: 'confirmed',
        activeTab: 4,
        ...payload,
      };
    }
    case CONFIRM_REPORT_LOADING: {
      return {
        ...state,
        confirmReportStatus: 'loading',
        contacts: payload,
      };
    }
    case CONFIRM_REPORT_ERROR: {
      return {
        ...state,
        confirmReportStatus: 'error',
      };
    }
    case RESET_CONTEXT: {
      return {
        ...state,
        ...initialState
      };
    }
    case HANDLE_CHANGE_CUSTOMS_DECLARATION: {
      return {
        ...state,
        ...payload
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

function BaggageProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <BaggageContext.Provider value={{ state, dispatch }}>
      {children}
    </BaggageContext.Provider>
  );
}

const useBaggageContext = () => useContext(BaggageContext);

export { BaggageProvider, BaggageContext, useBaggageContext };
