import { Button } from "@mui/material";

function SASButton({ children, sx, ...props }) {

    return (
        <Button
            type="submit"
            sx={{
                // maxWidth: { sm: '150px', xs: 'auto' },
                // width: '100%',
                // boxShadow: 'none',
                // alignSelf: { sm: 'end', xs: 'center' },
                // marginTop: '20px',

                bgcolor: '#ffd5ad',
                color: '#000066',
                borderRadius: '100vmax',
                '&:hover': {
                    transition: 'all .5s',
                    background: '#f2ba91',

                },
                ...sx,
            }}
            {...props}
        >
            {children}
        </Button>
    );
}

export default SASButton;
