import { useMemo, useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useBaggageContext } from 'context';
import SectionWrapper from 'components/SectionWrapper';

const FlightList = ({
  handleModal,
  handleExpandSections,
  handleConfirmItinerary,
}) => {
  const {
    state: {
      searchType,
      flights,
      airports,
      expandSections,
      texts: {
        'travel-ssdbr-label-itinerary': title = '',
        'travel-ssdbr-label-itinerary-text': subtitle = '',
        'travel-ssdbr-addItinerary': linkText = '',
        'travel-ssdbr-label-confirmItineraryButton': button = '',
      } = {},
    },
  } = useBaggageContext();

  const flightSectionCollapsedText = useMemo(() => {
    const flight = flights[flights?.length - 1];
    if (!flight) return `No flights`;
    return `Arriving in ${flight?.arrival?.station} on ${(flight?.operatingCarrier || '') + ' ' + flight?.operatingFlightNumber
      } ${moment(flight?.departure?.scheduledTime?.local).format('MMM DD')}`;
  }, [flights]);

  const getAirportName = useCallback(
    (code) =>
      airports?.find((point) => point?.airport_code === code) || {},
    [airports]
  );

  const isBagSearch = useMemo(
    () => searchType === 'charter' && flights?.length === 0,
    [searchType, flights]
  );

  return (
    <SectionWrapper
      expand={expandSections.flights}
      id="flights-section"
      title={{ jsx: title?.title }}
      summaryText={expandSections?.flights ? '' : flightSectionCollapsedText}
      handleChange={() => handleExpandSections({ flights: true })}
      subtitle={subtitle?.description}
      linkClick={() => (isBagSearch ? {} : handleModal(true, 'add-itinerary'))}
      linkText={isBagSearch ? '' : linkText?.description}
      button={isBagSearch ? 'Add your flight' : button?.title}
      buttonClick={() =>
        isBagSearch
          ? handleModal(true, 'add-itinerary')
          : handleConfirmItinerary()
      }
      buttonProps={{
         disabled: isBagSearch ? false : !flights.length,
      }}
    >
      {flights?.map((flight, index) => (
        <Grid container key={index} p="12px 0" alignItems="center">
          <Grid item xs={4} sm={2}>
            <Typography variant="h5" color="primary">
              {(flight?.departure?.station || '') +
                ' - ' +
                (flight?.arrival?.station || '')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={8}
            sm={4}
            sx={{ textAlign: { xs: 'end', sm: 'start' } }}
          >
            <Typography
              variant="subtitle3"
              color="primary"
              fontFamily="fontFamilyBold"
            >
              {getAirportName(flight?.departure?.station)?.city_name +
                ' - ' +
                getAirportName(flight?.arrival?.station)?.city_name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            sm={2}
            sx={{ textAlign: { xs: 'start', sm: 'center' } }}
          >
            <Typography variant="subtitle3">
              {['NEW'].includes(flight?.status)
                ? ''
                : `${moment(flight?.departure?.scheduledTime?.local).format(
                  'HH:mm'
                )} - ${moment(flight?.arrival?.scheduledTime?.local).format(
                  'HH:mm'
                )}`}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2} sx={{ textAlign: 'center' }}>
            <Typography variant="subtitle3">
              {moment(flight?.departure?.scheduledTime?.local).format('MMM DD')}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={2} sx={{ textAlign: 'end' }}>
            <Typography variant="subtitle3">
              {(flight?.operatingCarrier || '') +
                ' ' +
                flight?.operatingFlightNumber}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </SectionWrapper>
  );
};

export default FlightList;
