import { Box, Grid, Typography } from '@mui/material';
import SectionWrapper from 'components/SectionWrapper';
import { useBaggageContext } from 'context';
import { useFormik } from 'formik';
import { boundDetailsSchema } from 'helpers/validationSchema';
import AirportAutocomplete from 'components/AirportAutocomplete';
import { useCallback } from 'react';
import { handleChangeState } from 'context/actions';
import { getBoundBaggage, in7DaysWindow } from 'helpers/boundUtils';
import { getBagJourney } from 'api/booking';
import moment from 'moment';
import SASButton from 'SASButton';

const BoundDetails = ({ handleExpandSections, handleConfirmBound }) => {
  const {
    state: {
      airports,
      expandSections,
      originBound,
      destinationBound,
      trips,
      flights,
      baggage,
      isGroupBooking,
      texts: {
        'travel-ssdbr-boundDetails': boundDetailsText = '',
        'travel-ssdbr-boundDetailsOrigin': boundDetailsOriginText = '',
        'travel-ssdbr-boundDetailsDestination': boundDetailsDestinationText = '',
        'travel-ssdbr-destination': destinationText = '',
        'travel-ssdbr-origin': originText = '',
        'travel-ssdbr-label-continueButton': continueButton = ''
      } = {}
    },

    dispatch,
  } = useBaggageContext();

  const setStatusForBagtags = useCallback(
    async (values, flights) => {
      let baggageStatuses = [];
      const flight = flights[0];
      for (const bag of values) {
        const response = await getBagJourney({
          bagTagNumber: bag.number,
          departureDate: moment(flight?.departure?.scheduledTime?.local).format(
            'YYYY-MM-DD'
          ),
          flightNumber:
            flight?.operatingCarrier + flight?.operatingFlightNumber,
        });
        if(!response?.currentBagStatus) {
          baggageStatuses.push({
            ...bag,
          });
        }
        else if (response?.currentBagStatus?.bagDetails?.outboundStatus !== 'CLD') {
          baggageStatuses.push({
              ...response?.currentBagStatus,
            });
        }
      }
      dispatch(handleChangeState({
        bagTags: baggageStatuses
      }));
    },
    [dispatch, flights]
  );

  const handleSubmit = useCallback(
    (origin, destination) => {
      const bound = trips?.find(
        (trip) =>
          trip?.status === 'Flown' &&
          trip?.destination?.station === destination?.slice(-3) &&
          trip?.origin?.station === origin?.slice(-3) &&
          in7DaysWindow(trip?.destination.scheduledTime?.utc) &&
          trip?.segments[trip.segments.length - 1]?.flight
            ?.operatingCarrier === 'SK'
      );

      const flights = bound
        ? bound.segments?.map((segment) => segment?.flight)
        : [];
      const bags = bound ? getBoundBaggage(bound, baggage) : [];

      dispatch(
        handleChangeState({
          originBound: origin,
          destinationBound: destination,
          flights: flights
        })
      );
      !isGroupBooking && bags.length && flights.length && setStatusForBagtags(bags, flights);
      handleConfirmBound();
    },
    [handleConfirmBound, dispatch, trips, isGroupBooking,setStatusForBagtags,baggage ]
  );

  const formik = useFormik({
    initialValues: {
      origin: originBound,
      destination: destinationBound
    },
    enableReinitialize: true,
    validationSchema: boundDetailsSchema(),
    onSubmit: ({ origin, destination }) => handleSubmit(origin, destination),
  });

  const handleContinue = useCallback(() => {
    const button = document.getElementById('bound-details-submit-button');
    if (button) button.click();
  }, []);

  return (
    <SectionWrapper
      expand={expandSections.bound}
      id="bound-section"
      title={{ jsx: boundDetailsText?.title }}
      summaryText={
        !expandSections?.bound
          ? `${originBound?.slice(-3)}-${destinationBound?.slice(-3)}`
          : ''
      }
      handleChange={() =>
        handleExpandSections({ bound: true, flights: false, baggage: false })
      }
      button={continueButton?.title}
      buttonClick={handleContinue}
    >
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', mt: '30px' }}
        onSubmit={formik?.handleSubmit}
      >
        <Grid container flexDirection="column">
          <Grid container flexDirection="column" mb="25px">
            <Typography mb="20px">
              {boundDetailsOriginText?.title}
            </Typography>
            <AirportAutocomplete
              content={airports}
              value={formik.values.origin}
              handleChange={formik.setFieldValue}
              name="origin"
              label={originText?.title}
              style={{
                maxWidth: { sm: '100%', xs: '100%' },
              }}
              wrapperStyle={{
                maxWidth: { sm: '50%', xs: '100%' },
              }}
              formik={formik}
            />
            <Typography variant="p" fontSize="12px" color="error">
              {formik?.touched["origin"] &&
                Boolean(formik?.errors["origin"]) &&
                `${formik?.errors["origin"]}`}
            </Typography>
          </Grid>
          <Grid container flexDirection="column">
            <Typography mb="20px">
              {boundDetailsDestinationText?.title}
            </Typography>
            <AirportAutocomplete
              content={airports}
              value={formik.values.destination}
              handleChange={formik.setFieldValue}
              name="destination"
              label={destinationText?.title}
              style={{
                maxWidth: { sm: '100%', xs: '100%' },
              }}
              wrapperStyle={{
                maxWidth: { sm: '50%', xs: '100%' },
              }}
              formik={formik}
            />
            <Typography variant="p" fontSize="12px" color="error">
              {formik?.touched["destination"] &&
                Boolean(formik?.errors["destination"]) &&
                `${formik?.errors["destination"]}`}
            </Typography>
          </Grid>
        </Grid>
        {/* <Button
          id="bound-details-submit-button"
          type="submit"
          sx={{ display: 'none' }}
        ></Button> */}
        <SASButton id="bound-details-submit-button"
          type="submit"
          sx={{ display: 'none' }}></SASButton>
      </Box>
    </SectionWrapper>
  );
};

export default BoundDetails;
