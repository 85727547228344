import { Box, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <Box component="footer" width="100%">
      <Grid
        container
        flexDirection="column"
        position="relative"
        maxWidth="1152px"
        m="auto"
        sx={{ p: { sm: '31.5px 24px 32.5px', xs: '29px 0 30px' } }}
      >
        <Box
          height="1px"
          width="100%"
          sx={{
            bgcolor: ({ palette }) => palette.text.primary,
            position: {
              sm: 'initial',
              xs: 'absolute',
            },
            top: 'calc(50% + 20px)',
            transform: 'translateY(-50%)',
          }}
        />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            maxWidth: { sm: '100%', xs: '250px' },
            m: { sm: '31px 0 0', xs: 'auto' },
          }}
        >
          <Typography
            variant="p"
            fontSize="14px"
            lineHeight="24px"
            sx={{
              textAlign: { sm: 'initial', xs: 'center' },
              mb: { sm: '10px', xs: '66px' },
            }}
          >
            {`© ${year} Scandinavian Airlines System-Denmark-Norway-Sweden, reg. nr
            902001-7720, 195 87 Stockholm`}
          </Typography>
          {/* <Box
            position="relative"
            sx={{ cursor: 'pointer', m: { sm: '0 0 10px', xs: 'auto' } }}
          >
            <Link
              href="https://www.staralliance.com/en/home"
              target="_blank"
              rel="noreferrer"
            >
              <s4s-icon
                style={{ display: 'unset' }}
                name="b--star-alliance-dark"
              />
            </Link>
          </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
