import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useBaggageContext } from 'context';
import { Box, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import Input from 'components/Input';
import { scheduleSearchSchema } from 'helpers/validationSchema';
import SASButton from 'SASButton';

const ScheduleFlight = () => {
  const {
    state: {
      pnr,
      lastName,
      texts: {
        'travel-ssdbr-label-searchButton': searchButton = '',
        'travel-ssdbr-label-bookingReference': pnrLabel = '',
        'travel-ssdbr-label-lastName': lastNameLabel = '',
        'travel-ssdbr-label-error-minBookingReference': minPnrError = '',
        'travel-ssdbr-label-error-maxBookingReference': maxPnrError = '',
        'travel-ssdbr-label-error-minLastName': minLastNameError = '',
        'travel-ssdbr-label-error-maxLastName': maxLastNameError = '',
        'travel-ssdbr-label-error-noBookingReference': noPnrError = '',
        'travel-ssdbr-label-error-noLastName': noLastNameError = ''
      } = {},
    },
  } = useBaggageContext();
  const navigate = useNavigate();
  const validationValues = {
    pnr: {
      min: 5,
      max: 6,
    },
    lastName: {
      min: 2,
      max: 16,
    },
    informativeTexts: {
      minPnrError,
      maxPnrError,
      minLastNameError,
      maxLastNameError,
      noPnrError,
      noLastNameError
    }
  };

  const formik = useFormik({
    initialValues: {
      pnr,
      lastName,
    },
    enableReinitialize: true,
    validationSchema: scheduleSearchSchema(validationValues),
    onSubmit: ({ pnr, lastName }) => {
      navigate(
        process.env.PUBLIC_URL + `/search?pnr=${pnr}&lastName=${lastName}`
      );
    },
  });

  const getLabel = useCallback(
    (name) => {
      const labels = {
        lastName: lastNameLabel,
        pnr: pnrLabel,
      };
      return labels[name]?.title || '';
    },
    [lastNameLabel, pnrLabel]
  );

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column' }}
      onSubmit={formik?.handleSubmit}
    >
      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        sx={{
          flexDirection: {
            sm: 'row',
            xs: 'column',
          },
          alignItems: {
            sm: 'initial',
            xs: 'center',
          },
        }}
      >
        {['pnr', 'lastName'].map((field, index) => (
          <Grid
            key={index}
            item
            width="100%"
            sm={6}
            xs={12}
            flexDirection="column"
            sx={{
              mr: { sm: '20px', xs: '0' },
              mb: {
                sm: '0',
                xs: '20px',
              },
              '&:last-child': {
                mr: '0',
              },
            }}
          >
            <Input
              name={field}
              label={getLabel(field)}
              value={formik?.values[field]}
              handleChange={formik?.handleChange}
              formik={formik}
              validvalues={validationValues[field]}
            />
            <Typography variant="p" fontSize="12px" color="error">
              {formik?.touched[field] &&
                Boolean(formik?.errors[field]) &&
                `${formik?.errors[field]}`}
            </Typography>
          </Grid>
        ))}
      </Grid>
      {/* <Button
        type="submit"
        color="secondary"
        variant="contained"
        sx={{
          maxWidth: { xs: 'auto', sm: '121px' },
          m: '30px 0 0 auto',
          fontSize: '14px',
        }}
      >
        {searchButton?.title}
      </Button> */}
      <SASButton type="submit"
        sx={{
          maxWidth: { xs: 'auto', sm: '121px' },
          m: '30px 0 0 auto',
          fontSize: '14px',
        }}> {searchButton?.title}</SASButton>
    </Box>
  );
};

export default ScheduleFlight;
