import { Grid, Typography } from '@mui/material';
import Input from 'components/Input'
import { useBaggageContext } from 'context'
import React, { useCallback } from 'react'

const TicketInfo = ({ formik, validationValues, inputStyle, searchType }) => {
    const {
        state: {
            texts: {
                'travel-ssdbr-label-ticketInfoLabel': ticketInfoLabel = '',
                'travel-ssdbr-label-ebNumber': ebNumberLabel = '',
                'travel-ssdbr-label-optional': optional = ''
            } = {},
        },
    } = useBaggageContext();

    const handleChangeInput = useCallback(
        (e) => {
            if(e.target.value.length === 3 && e?.nativeEvent?.inputType !== 'deleteContentBackward') {
                    e.target.value = e?.target?.value + '-';
            }
            formik?.setFieldValue('ticketNumber', e.target.value)
        },
        [formik]
    )
    const checkIfOptional = ()  => {
        if(searchType === 'charter')
            return optional?.title;
        else 
            return '';
    }
    return (
    <Grid container flexWrap="nowrap" flexDirection="column" id="ticket-section">
        <Grid
            container
            flexWrap="nowrap"
            sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
        >
            <Input
                name="ticketNumber"
                label={ticketInfoLabel?.title + ` ${checkIfOptional()}`}
                value={formik?.values?.ticketNumber}
                handleChange={handleChangeInput}
                formik={formik}
                style={{ mt: { xs: '10px', sm: '0'}, ...inputStyle}}
                validvalues={validationValues['ticketNumber']}
            />
            <Grid
                container
                sx={{ flexDirection: 'column' }}
              >
                <Input
                    name="ebNumber"
                    label={ebNumberLabel?.title + ` ${optional?.title}`}
                    value={formik?.values?.ebNumber}
                    handleChange={formik?.handleChange}
                    formik={formik}
                    style={{mr:'0', ...inputStyle}}
                    validvalues={validationValues['ebNumber']}
                />
                <Typography variant="caption" fontSize="12px" color="error">
                    {formik?.touched["ebNumber"] &&
                    Boolean(formik?.errors["ebNumber"]) &&
                    `${formik?.errors["ebNumber"]}`}
                </Typography>
            </Grid>
        </Grid>
        </Grid>
    )
}

export default TicketInfo