import { InputAdornment, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const Input = ({
  name,
  label,
  value,
  handleChange,
  formik,
  type,
  disabled,
  style,
  params,
  validvalues,
}) => {
  const [showLimits, setShowLimits] = useState(false);
  const [controlsTimeout, setControlsTimeout] = useState();
  const [showError, setShowError] = useState(false);

  return (
    <TextField
      sx={{
        ...style,
        '& .css-pa91cz-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: '#e81e14 !important',
        }
      }}
      disabled={disabled}
      id={name}
      autoComplete="off"
      name={name}
      type={type}
      value={value}
      onChange={(e) => {
        if (
          !validvalues ||
          (validvalues && value.length < validvalues?.max) ||
          (validvalues &&
            value.length <= validvalues?.max &&
            !e.nativeEvent?.data)
        ) {
          handleChange(e);
        } else {
          setShowError(true);
          clearTimeout(controlsTimeout);
          setControlsTimeout(setTimeout(() => setShowError(false), 5000));
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Backspace" && validvalues && value?.length >= validvalues?.max) {
          handleChange({
            target: {
              name: name,
              value: value?.slice(0, -1)
            }
          });
        }
      }}
      label={label}
      onFocus={() => setShowLimits(true)}
      onBlur={() => setShowLimits(false)}
      InputProps={{
        endAdornment: validvalues && (
          <InputAdornment
            sx={{ display: showLimits ? 'initial' : 'none' }}
            position="end"
          >
            <Typography
              color={showError ? 'red' : 'textSecondary'}
              sx={{ marginLeft: '-30px', marginTop: '-25px' }}
              fontSize="12px"
            >
              {value?.length}/{validvalues.max}
            </Typography>
          </InputAdornment>
        ),
      }}
      {...params}
      error={formik?.touched[name] && Boolean(formik?.errors[name])}
    />
  );
};

export default Input;
