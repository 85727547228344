import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useBaggageContext } from 'context';
import { Box, Grid, Typography, Button } from '@mui/material';
import Input from 'components/Input';
import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import moment from 'moment';
import { charterSearchSchema } from 'helpers/validationSchema';
import SASButton from 'SASButton';

const CharterFlight = () => {
  const {
    state: {
      bagtagNumber,
      flightNumber,
      date,
      texts: {
        'travel-ssdbr-label-searchButton': searchButton = '',
        'travel-ssdbr-label-BagtagNumber': bagtagNumberLabel = '',
        'travel-ssdbr-label-flightNumber': flightNumberLabel = '',
        'travel-ssdbr-label-date': dateLabel = '',
        'travel-ssdbr-label-error-minBagtag': minBagtagError = '',
        'travel-ssdbr-label-error-maxBagtag': maxBagtagError = '',
        'travel-ssdbr-label-error-minFlightNumber': minFlightNumberError = '',
        'travel-ssdbr-label-error-maxFlightNumber': maxFlightNumberError = '',
        'travel-ssdbr-label-error-noLastName': noLastNameError = '',
        'travel-ssdbr-label-error-noDate': noDateError = ''
      } = {},
    },
  } = useBaggageContext();
  const navigate = useNavigate();

  const informativeTexts = {
    minBagtagError,
    maxBagtagError,
    minFlightNumberError,
    maxFlightNumberError,
    noLastNameError,
    noDateError
  };

  const formik = useFormik({
    initialValues: {
      bagtagNumber,
      flightNumber,
      date: date || moment().format('YYYY-MM-DD'),
    },
    enableReinitialize: true,
    validationSchema: charterSearchSchema(informativeTexts),
    onSubmit: ({ bagtagNumber, flightNumber, date }) => {
      navigate(
        process.env.PUBLIC_URL +
        `/search?bagTagNumber=${bagtagNumber}&departureDate=${date}&flightNumber=${flightNumber}`
      );
    },
  });

  const getLabel = useCallback(
    (name) => {
      const labels = {
        bagtagNumber: bagtagNumberLabel,
        flightNumber: flightNumberLabel,
        date: dateLabel,
      };
      return labels[name]?.title || '';
    },
    [bagtagNumberLabel, flightNumberLabel, dateLabel]
  );

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column' }}
      onSubmit={formik?.handleSubmit}
    >
      <Grid
        container
        justifyContent="space-between"
        flexWrap="nowrap"
        sx={{
          flexDirection: {
            sm: 'row',
            xs: 'column',
          },
          alignItems: {
            sm: 'initial',
            xs: 'center',
          },
        }}
      >
        {['bagtagNumber', 'flightNumber'].map((field, index) => (
          <Grid
            key={index}
            item
            width="100%"
            sm={4}
            xs={12}
            flexDirection="column"
            sx={{
              mr: { sm: '20px', xs: '0' },
              mb: {
                sm: '0',
                xs: '20px',
              },
              '&:last-child': {
                mr: '0',
              },
            }}
          >
            <Input
              name={field}
              label={getLabel(field)}
              value={formik?.values[field]}
              handleChange={formik?.handleChange}
              formik={formik}
            />
            <Typography variant="p" fontSize="12px" color="error">
              {formik?.touched[field] &&
                Boolean(formik?.errors[field]) &&
                `${formik?.errors[field]}`}
            </Typography>
          </Grid>
        ))}
        <Grid
          item
          width="100%"
          sm={4}
          xs={12}
          flexDirection="column"
          sx={{
            mr: { sm: '20px', xs: '0' },
            mb: {
              sm: '0',
              xs: '20px',
            },
            '&:last-child': {
              mr: '0',
            },
          }}
        >
          <LocalizationProvider dateAdapter={DateAdapter}>
            <MobileDatePicker
              name="date"
              disableFuture={true}
              minDate={moment(moment().subtract(6, 'days').format('YYYY-MM-DD'))}
              value={formik?.values?.date}
              onChange={(newValue) => {
                formik.setFieldValue('date', newValue.format('YYYY-MM-DD'));
              }}
              label={getLabel('date')}
              inputFormat="MMM DD"
              showToolbar={false}
              renderInput={(params) => (
                <Input
                  params={params}
                  name="date"
                  label={getLabel('date')}
                  formik={formik}
                />
              )}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      {/* <Button
        type="submit"
        color="secondary"
        variant="contained"
        sx={{
          maxWidth: { xs: 'auto', sm: '121px' },
          m: '30px 0 0 auto',
          fontSize: '14px',
        }}
      >
        {searchButton?.title}
      </Button> */}
      <SASButton type="submit"
        sx={{
          maxWidth: { xs: 'auto', sm: '121px' },
          m: '30px 0 0 auto',
          fontSize: '14px',
        }}>
        {searchButton?.title}
      </SASButton>
    </Box>
  );
};

export default CharterFlight;
