const LogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72 26"
      className="sas-logo"
      width="66px"
      height="24px"
    >
      <path
        fill="#fff"
        d="M47.2.807h-7.234c-.37 0-.556.18-.556.18L19.473 25.013c-.093.18-.093.269.093.269h2.967c.278 0 .371-.09.371-.18l4.266-5.29s.092-.089.185-.089h7.14s.093 0 .093.09-1.02 5.2-1.02 5.2c0 .09 0 .269.278.269h8.624c.093 0 .186 0 .186-.18l4.822-24.027c-.093 0 0-.27-.279-.27zM34.958 17.303h-5.564-.092v-.09L36.72 7.98l.093-.09c.092 0 0 .09 0 .09l-1.855 9.324c.093-.09.093-.09 0 0zM.185 22.234s.65-2.151.742-2.062c0-.358.186-.448.371-.269.835.807 3.431 3.228 7.048 3.228 3.987 0 3.709-3.138 3.709-3.586 0-1.255-1.67-7.442-2.133-9.055C8.624 5.29 11.87.538 19.566.538c4.08 0 6.12 1.076 6.862 1.434.464.27.37.628.37.628s-.463 1.883-.463 2.062c-.093.18-.278.18-.463 0-1.206-.717-2.597-1.524-4.73-1.524-2.503 0-3.802 1.793-3.152 4.393.278.897 1.854 7.083 2.132 8.338 1.113 4.483-2.04 9.862-10.57 9.862-4.73 0-7.697-1.703-8.717-2.51-.557-.449-.742-.718-.65-.987zm45.345.09s.464-2.33.464-2.152c.093-.269.37-.358.556-.179.928.807 3.338 3.138 6.955 3.138 3.987 0 3.71-3.138 3.71-3.586 0-1.255-1.67-7.442-2.134-9.055C53.783 5.29 57.03.538 64.725.538c4.08 0 6.12 1.076 6.862 1.434.464.27.371.628.371.628s-.464 1.883-.464 2.062c-.092.18-.278.18-.463 0-1.206-.717-2.597-1.524-4.73-1.524-2.503 0-3.801 1.793-3.152 4.393.278.897 1.854 7.083 2.133 8.338 1.112 4.483-2.04 9.862-10.572 9.862-4.729 0-7.51-1.614-8.53-2.42-.557-.449-.743-.718-.65-.987z"
      ></path>
    </svg>
  );
};

export default LogoIcon;
