import { useState, useMemo, useRef, Fragment, useCallback } from 'react';
import { Grid, Typography, Box, ClickAwayListener, Autocomplete, Popper } from '@mui/material';
import Input from 'components/Input';
import airports from 'helpers/airports.json';

const CountryAutocomplete = ({
  value,
  handleChange,
  label,
  name,
  style,
  formik,
}) => {

  const content = useMemo(() => {
    return airports?.countries?.map((item) => ({
      code: item.code,
      name: item.name
    })).filter(item => item.code && item.name);
  }, []);

  const CustomPopper = (props) => { return (<Popper {...props} placement="bottom" sx={{ height: "10px", }} style={{ width: props.anchorEl.clientWidth, height: "5px" }} />); }
  return (
    // <Box
    //   sx={{
    //     position: 'relative',
    //     width: '100%',
    //     mr: '10px',
    //   }}
    // >
    //   <Input
    //     id={label}
    //     name={name}
    //     fullWidth
    //     value={value}
    //     handleChange={handleChangeInput}
    //     label={label}
    //     params={{
    //       autoComplete: 'off',
    //       onClick: handleClickInput,
    //       onBlur: handleBlurInput,
    //     }}
    //     style={style}
    //     formik={formik}
    //   />
    //   {expand && (
    //     <ClickAwayListener onClickAway={handleClickAway}>
    //       <Grid
    //         item
    //         ref={selectRef}
    //         sx={{
    //           position: 'absolute',
    //           zIndex: '2',
    //           width: '100%',
    //           maxHeight: '170px',
    //           backgroundColor: '#fff',
    //           overflow: 'scroll',
    //           border: '2px solid #DAD9D9',
    //           borderTop: 'none',
    //           borderBottomLeftRadius: '4px',
    //           borderBottomRightRadius: '4px',
    //           top: '57px',
    //           overflowX: 'hidden',
    //           maxWidth: {
    //             sm: style?.maxWidth?.sm,
    //             xs: 'auto',
    //           },
    //         }}
    //       >
    //         {search &&
    //           search.map((el, index) => (
    //             <Grid
    //               container
    //               alignItems="center"
    //               justify="space-between"
    //               sx={{
    //                 padding: '10px',
    //                 cursor: 'pointer',
    //                 '&:hover': {
    //                   backgroundColor: '#DEDEDE',
    //                 },
    //               }}
    //               key={index}
    //               onClick={() => handleSelectInput(el)}
    //             >
    //               {items(el)}
    //             </Grid>
    //           ))}
    //       </Grid>
    //     </ClickAwayListener>
    //   )}
    // </Box>

    <Autocomplete
      disableClearable
      forcePopupIcon={false}
      options={content}
      getOptionLabel={(option) => option ? `${option?.code} - ${option?.name}` : ''}
      PopperComponent={CustomPopper}
      value={value}
      sx={{
        width: 'inherit',
        mr: '10px',
      }}
      isOptionEqualToValue={(option, value) => option?.code === value?.code}
      onChange={(e, v) => handleChange(name, v)}
      renderInput={(params) => {
        return (
          <Input
            name={name}
            label={label}
            handleChange={params?.inputProps?.onChange}
            formik={formik}
            params={{
              ...params,
            }}
            style={style}
          />
        )
      }
      }
    />
  );
};

export default CountryAutocomplete;
