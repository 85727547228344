import { Alert, Box, Button, FormControl, FormControlLabel, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import SectionWrapper from 'components/SectionWrapper'
import { useBaggageContext } from 'context'
import React from 'react'
import CircleSharpIcon from '@mui/icons-material/CircleSharp';
import { handleChangeDeclaration } from 'context/actions';
import { useCallback } from 'react';
import { CUSTOM_DECLARATION_AIRPORTS, FRANCE_DESTINATION } from 'helpers/constant';


const CustomDeclaration = ({
    handleExpandSections
}) => {
    const { state: {
        flights,
        expandSections,
        customDeclaration,
        texts: {
            'travel-ssdbr-label-continueButton': continueButton = '',
            'travel-ssbdr-label-customsDeclaration': customTitle = {
                title: 'Customs declaration' 
            },
            'travel-ssdbr-label-customsSubtitle': customsSubtitle = '',
            'travel-ssdbr-label-declareGoods': declareGoods = '',
            'travel-ssdbr-label-customText1': customText1 = '',
            'travel-ssdbr-label-customTextFrance': franceCustomText= '',
            'travel-ssdbr-label-customWarning': customWarning = '',
            'travel-ssdbr-label-goodsDeclare': goodsDeclare = '',
            'travel-ssdbr-label-nothingToDeclare': nothingToDeclare = ''
        } = {},
    }, dispatch
    } = useBaggageContext();
    const declarationOptions = [ {label: goodsDeclare?.title, value: 'Goods To Declare'}, {label: nothingToDeclare?.title, value: 'Nothing To Declare' }];
    const handleContinue = useCallback(() => {
        handleExpandSections({  contactInformation: true, customDeclaration: false });
        document.getElementById('custom-declaration-section')?.scrollIntoView({behavior : 'smooth'});
    }, [dispatch, handleExpandSections])
    const station = flights[flights.length-1]?.arrival?.station;
    const text = FRANCE_DESTINATION.includes(station) ?  franceCustomText : customText1 ;

    const updateCustomDescription = (customDescription) => {
        return customDescription.replace("<a href=\"http://www.customs.admin.ch\">www.customs.admin.ch</a>",
         "<a href=\"http://www.customs.admin.ch\" target=\"_blank\">www.customs.admin.ch</a>");
    }

    return (
        <SectionWrapper
            expand={expandSections.customDeclaration}
            id="custom-declaration-section"
            title={{ jsx: customTitle?.title }}
            summaryText={expandSections?.customDeclaration ? '' : `${customDeclaration}`}
            handleChange={() => handleExpandSections({ customDeclaration: true })}
            subtitle={customsSubtitle?.title}
            button={continueButton?.title}
            buttonClick={() => handleContinue()
            }
            buttonProps={{ disabled: !customDeclaration.length }}
        >
            <Box component={"div"}>
                <Typography variant="baggageList" fontFamily="fontFamilyBold" >
                    {declareGoods?.title}
                </Typography>
                <Typography
                    variant="p"
                    pt="10px"
                    dangerouslySetInnerHTML={{
                    __html: updateCustomDescription(text?.description),
                    }}
                />
                {/* <List
                >
                    {
                        ["Your items may be siezed.", "You may be fined or prosecuted."].map((text, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemIcon>
                                    <CircleSharpIcon fontSize='small' />
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))
                    }
                </List> */}
                <Grid container >
                    <FormControl>
                        <RadioGroup
                            row
                            name="custom-declaration-radio-group"
                            value={customDeclaration}
                            onChange={(e) => {
                                dispatch(handleChangeDeclaration(e.target.value))
                            }}
                        >
                            {declarationOptions.map((option, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={option.value}
                                    control={<Radio/>}
                                    label={option.label}
                                    sx={{ mr: '45px', '& .MuiFormControlLabel-label': { fontSize: '16px' } }}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {
                    customDeclaration === declarationOptions[0].value &&
                    <Alert sx={{ mt: "15px" }} severity="warning" >
                        {customWarning?.title}
                    </Alert>
                }
            </Box>
        </SectionWrapper>
    )
}

export default CustomDeclaration