const LOCALHOST = ['localhost', '127.0.0.1']; // TODO: Add dev url
let D360_BASE_URL = 'https://www.flysas.com';
let API_BASE_URL;

let ENV;
let SITA_BAGGAGE_TOOL_URL = '';

const { hostname } = window.location;

if (hostname.indexOf('test') > -1 || LOCALHOST.indexOf(hostname) > -1 || hostname.indexOf('travelt') > -1) {
  API_BASE_URL = 'https://travelt.flysas.com';
  ENV = 'develop';
  SITA_BAGGAGE_TOOL_URL = 'https://mybag-demo.aero/sas';
} else {
  API_BASE_URL = 'https://travel.flysas.com';
  ENV = 'production';
  SITA_BAGGAGE_TOOL_URL = 'https://mybag.aero/sas';
}

const isPROD = ENV === 'production';
const isTEST = ENV === 'develop';
const GEOAPIFY_API_KEY = '69f5e30a0a6d44e6ab3559a217a6b06f';

const config = {
  API_BASE_URL,
  D360_BASE_URL,
  ENV,
  isPROD,
  isTEST,
  GEOAPIFY_API_KEY,
  SITA_BAGGAGE_TOOL_URL,
};

export default config;
