import { Fragment, useCallback, useEffect, useState } from 'react';
import { Alert, Grid, Snackbar } from '@mui/material';
import { useBaggageContext } from 'context';
import {
  handleChangeModal,
  handleAddFlights,
  handleAddBaggage,
  handleChangeState,
  handleExpandSections as handleSections,
  handleUpdateBaggageStatus
} from 'context/actions';
import Modal from 'components/Modal';
import AddItineraryModal from 'components/AddItineraryModal';
import AddBaggageModal from 'components/AddBaggageModal';
import BaggageList from 'components/BaggageList';
import FlightList from 'components/FlightList';
import BaggageDetails from 'components/BaggageDetails';
import ContactInformation from 'components/ContactInformation';
import { getBagJourney, getReportBaggages, checkFlight, saveInitReport } from 'api/booking';
import moment from 'moment';
import Loader from 'components/Loader';
import BoundDetails from 'components/BoundDetails';
import { formatFlightNumber, in7DaysWindow, mapFlight } from 'helpers/boundUtils';
import CustomDeclaration from 'components/CustomDeclaration';
import { CUSTOM_DECLARATION_AIRPORTS } from 'helpers/constant';
import { useBackListener } from 'hooks/useBackListener';

const SearchResults = () => {
  const [loadingBaggage, setLoadingBaggage] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    message: '',
  });
  const [showBaggage, setShowBaggage] = useState(false);
  const [showFlights, setShowFlights] = useState(false);
  const [checkedList, setCheckedList] = useState({});
  const [showCustomDeclaration,setShowCustomDeclaration] = useState(false);

  const {
    state: {
      searchType,
      modal,
      flights,
      expandSections,
      originBound,
      destinationBound,
      bagTags,
      pnr,
      lastName,
      bagtagNumber,
      flightNumber,
      date,
      texts: {
        'travel-ssdbr-label-reportexists': errorMessage = '',
        'travel-ssdbr-label-error-generic': defaultError = '',
        'travel-ssdbr-error-7days': sevendaysError = '',
        'travel-ssdbr-error-invalidFlight': invalidFlight = '',
        'travel-ssdbr-error-nonSK': errorNonSK = '',
        'travel-ssdbr-error-ondMismatch': flightMismatch = '',
        'travel-ssdbr-label-invalidBagtag': invalidBagtag = ''
      } = {},
    },
    dispatch,
  } = useBaggageContext();

  useBackListener(()=>
    dispatch(handleChangeState({ getBookingStatus:'' }))
  );

  const handleExpandSections = useCallback(
    (obj) => dispatch(handleSections(obj)),
    [dispatch]
  );

  const handleModal = useCallback(
    (open, target) => dispatch(handleChangeModal(open, target)),
    [dispatch]
  );
  const saveReport = useCallback( async () => {
    const body = {
      pnr, lastName, bagtagNumber, flightNumber, date
    };
    const response = await saveInitReport(body);
    if (response.status >= 200) {
      dispatch(handleChangeState({ transactionId: response?.transactionId }))
    }
  }, [pnr, lastName, bagtagNumber, flightNumber, date, dispatch]);

  const handleChangeSelect = useCallback(
    (e, tag) =>
      !e?.target?.checked
        ? setCheckedList((prev) => ({
          ...(delete prev[tag] && prev),
        }))
        : setCheckedList((prev) => ({ ...prev, [tag]: true })),
    []
  );
  const handleConfirmBound = useCallback(() => {
    handleExpandSections({ flights: true, bound: false });
    setShowFlights(true);
    setTimeout(() => {
      const flightsSection = document.getElementById('flights-section');
      if (flightsSection) flightsSection.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, [handleExpandSections]);

  const handleConfirmItinerary = useCallback(() => {
    handleExpandSections({ flights: false, baggage: true });
    setShowBaggage(true);
    setCheckedList({});
    setTimeout(() => {
      const baggageSection = document.getElementById('baggage-section');
      if (baggageSection) baggageSection.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, [handleExpandSections]);

  const handleConfirmBaggage = useCallback(async () => {
    setLoadingBaggage(true);
    const bagsDetails = Object.keys(checkedList)
      .map((bag, index) => ({
        [bag]: { show: index === 0 ? true : false, accepted: false },
      }))
      .reduce((prev, curr) => ({ ...prev, ...curr }), {});
    const lastFlight = flights[flights.length - 1];
    const params = {
      bagtags: Object.keys(checkedList).join(','),
      station: lastFlight?.arrival?.station,
      airlineCode: lastFlight?.operatingCarrier,
    };
    try {
      const response = await getReportBaggages(params);
      if (response.status === 200 && response?.bagRecords?.bagRecord?.find(record => record.recordIdentifier?.recordType?.toUpperCase() === 'DELAYED' && record.status != 'CLOSED')) {
        setShowSnackbar({
          show: true,
          message:
            errorMessage?.title ||
            'Report for the bags has been created already',
        });
      }
      else {
        saveReport();
        handleExpandSections({
          flights: false,
          baggage: false,
          bagsDetails,
          contactInformation: Object.keys(bagsDetails).length > 0 ? false : true,
        });
        dispatch(handleChangeState({ baggageDetails: {}, visitedTab: 2 }))
        setTimeout(() => {
          const firstCheckedBag = Object.keys(checkedList).length > 0 ? Object.keys(checkedList)[0] : '';
          const wrapper = document.getElementById(`baggage-details-${firstCheckedBag}-section`);
          if (wrapper)
            wrapper.scrollIntoView({ behavior: 'smooth' });
        }, 400)
          ;
      }
      setLoadingBaggage(false);
    } catch (err) {
      console.log(err);
      setLoadingBaggage(false);
    }
  }, [handleExpandSections, errorMessage, defaultError, checkedList, flights]);

  const isInputValid = useCallback((flights) => {
    const lastFlight = flights[flights.length - 1];
    if (searchType === 'charter' || (flights[0]?.originCity === originBound && lastFlight?.destinationCity === destinationBound)) {
      if (lastFlight?.flightNumber?.toUpperCase().startsWith('SK')) {
        return true;
      } else {
        setShowSnackbar({ show: true, message: errorNonSK?.title });
      }
    }
    else {
      setShowSnackbar({ show: true, message: flightMismatch?.title });
    }
    return false;
  }, [dispatch, originBound, destinationBound, searchType]);

  const onFlightsSubmit = useCallback(
    async ({ flights }, modal) => {
      const lastFlight = flights[flights.length - 1];
      if (isInputValid(flights)) {
        const lastPath = `ids=${formatFlightNumber(lastFlight?.flightNumber?.replaceAll(' ', ''))}-${lastFlight?.flightDate?.replaceAll('-', '')}-${lastFlight?.originCity?.slice(-3)}-${lastFlight?.destinationCity?.slice(-3)}&includeCharter=true`;
        const response = await checkFlight(lastPath);
        if (response?.status === 200) {
          const flightStatuses = response?.flightStatuses[0];
          if (!in7DaysWindow(flightStatuses?.arrivalDate?.dateUtc)) {
            setShowSnackbar({ show: true, message: sevendaysError?.title });
            return;
          }
          const checked = mapFlight(flightStatuses);
          setCheckedList({});
          const body = [
            ...flights
              ?.filter(
                (flight) => flight?.flightNumber !== lastFlight?.flightNumber
              )
              ?.map((flight) => ({
                operatingCarrier: flight?.flightNumber?.slice(0, 2)?.toUpperCase(),
                operatingFlightNumber: flight?.flightNumber?.slice(2),
                arrival: {
                  station: flight?.destinationCity.slice(-3),
                },
                departure: {
                  station: flight?.originCity.slice(-3),
                  scheduledTime: {
                    local: flight?.flightDate,
                  },
                },
                status: 'NEW',
              })),
            checked,
          ];
          dispatch(handleAddFlights(body));
          if (searchType !== 'charter') {
            dispatch(
              handleChangeState({ manuallyAddedFlights: true, bagTags: [] })
            );
          }
          if (!modal) {
            handleExpandSections({
              flights: false,
              baggage: true
            })
            setShowBaggage(true)
          }
        } else {
          setShowSnackbar({ show: true, message: invalidFlight?.title });
        }
      }
    },
    [dispatch, originBound, destinationBound, handleExpandSections]
  );

  const onBagsSubmit = useCallback(
    async (values) => {
      setLoadingBaggage(true);
      let bags = [];
      let invalidBags = [];
      const flight = flights[0];
      for (const bag of values.bags) {
        const isBagPresent = bagTags.find((existingBag) => existingBag?.number === bag?.number);
        if (isBagPresent) {
          setCheckedList((prev) => ({ ...prev, [bag.number]: true }));
          continue;
        }
        const response = await getBagJourney({
          bagTagNumber: bag?.number,
          departureDate: moment(flight?.departure?.scheduledTime?.local).format(
            'YYYY-MM-DD'
          ),
          flightNumber:
            flight?.operatingCarrier + flight?.operatingFlightNumber,
        });
        if (response?.currentBagStatus && response?.currentBagStatus?.bagDetails?.outboundStatus !== 'CLD') {
          bags.push({
            ...response?.currentBagStatus,
            number: response?.currentBagStatus?.bagDetails?.bagTagNumber,
            manually: true,
          });
          setCheckedList(prev => ({ ...prev, [bag.number]: true }));
        }
        else {
          invalidBags.push(bag?.number);
        }
      }
      if (invalidBags.length > 0) {
        setShowSnackbar({ show: true, message: `${invalidBagtag?.title} ${invalidBags.join()}` })
      }
      dispatch(handleAddBaggage(bags));
      setLoadingBaggage(false);
    },
    [dispatch, flights, bagTags]
  );

  useEffect(
    () =>
      dispatch(
        handleChangeState({
          activeTab:
            expandSections?.flights || expandSections?.baggage || expandSections.bound
              ? 1
              : expandSections?.contactInformation
                ? 3
                : 2,
        })
      ),
    [expandSections, dispatch]
  );

  const handleClose = () => setShowSnackbar({ show: false, message: '' });

  return (
    <div>
      <Loader open={loadingBaggage} />
      <Modal
        open={
          modal?.open &&
          ['add-itinerary', 'add-baggage'].includes(modal?.target)
        }
        handleModal={handleModal}
      >
        {modal?.target === 'add-itinerary' ? (
          <AddItineraryModal onSubmit={onFlightsSubmit} />
        ) : (
          <AddBaggageModal onSubmit={onBagsSubmit} />
        )}
      </Modal>
      <Snackbar
        open={showSnackbar.show}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          <span
            dangerouslySetInnerHTML={{ __html: showSnackbar.message }}
          ></span>
        </Alert>
      </Snackbar>
      <Grid item maxWidth="1052px" width="100%" m="auto" p="10px">
        {searchType !== 'charter' && <BoundDetails
          handleExpandSections={handleExpandSections}
          handleConfirmBound={handleConfirmBound}
        />}
        {(showFlights || searchType === 'charter') && (
          < FlightList
            setCheckedList={setCheckedList}
            handleModal={handleModal}
            handleExpandSections={handleExpandSections}
            handleConfirmItinerary={handleConfirmItinerary}
          />
        )}
        {showBaggage && (
          <BaggageList
            checkedList={checkedList}
            handleModal={handleModal}
            handleExpandSections={handleExpandSections}
            handleConfirmBaggage={handleConfirmBaggage}
            handleChangeSelect={handleChangeSelect}
          />
        )}
        {!expandSections?.flights &&
          !expandSections?.baggage &&
          Object.keys(checkedList).map((checkedBag, index) => (
            <BaggageDetails
              key={index}
              checkedBag={checkedBag}
              nextBag={Object.keys(checkedList)[index + 1]}
              index={index}
              handleExpandSections={handleExpandSections}
              setShowCustomDeclaration={setShowCustomDeclaration}
            />
          ))}
        {showCustomDeclaration
        && <CustomDeclaration handleExpandSections={handleExpandSections}/>}
        {expandSections.contactInformation && <ContactInformation />}
      </Grid>
    </div>
  );
};

export default SearchResults;
