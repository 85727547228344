import { Fragment, useCallback, useEffect, useState, useMemo } from 'react';
import { Typography, Grid, Box, Snackbar, Alert } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SearchResults from 'components/SearchResults';
import SearchForm from 'components/SearchForm';
import Loader from 'components/Loader';
import Confirmed from 'components/Confirmed';
import JsxParser from 'components/JsxParser';
import { checkFlight, getBooking, getBagJourney } from 'api/booking';
import { useBaggageContext } from 'context';
import {
  GET_BOOKING_LOADING,
  GET_BOOKING_ERROR,
  GET_BOOKING_SUCCESS,
} from 'context/constants';
import { handleChangeState } from 'context/actions';
import countryParser from 'helpers/countryParser';
import Airports from 'helpers/airports12.json';
import { formatFlightNumber, in7DaysWindow, mapFlight } from 'helpers/boundUtils';
import { redirectToSITA } from 'helpers/sita';

const Search = () => {
  useEffect(() => redirectToSITA(), []);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState({
    show: false,
    message: '',
  });
  const {
    state: {
      getTranslationsStatus,
      getBookingStatus,
      confirmReportStatus,
      notifyBy,
      texts: {
        'travel-ssdbr-retriveBooking': retrieveText = '',
        'travel-ssdbr-itinerary': itineraryText = '',
        'travel-ssdbr-submitted': submittedText = '',
        'travel-ssdbr-error-noFlownTrips': noFlownTripsText = '',
        'travel-ssdbr-label-error-bookingNotFound': bookingNotFoundText = '',
        'travel-ssdbr-label-error-charterNotFound': charterNotFound = '',
        'travel-ssdbr-label-error-charterNumberRange': charterNumberRange = '',
      } = {},
    },
    dispatch,
  } = useBaggageContext();
  const { search } = useLocation();
  const fetchBooking = useCallback(
    async (params) => {
      try {
        dispatch({ type: GET_BOOKING_LOADING });
        if (params.pnr) {
          const response = await getBooking(params);
          const isActive = response?.trips?.every(
            (trip) => trip?.status === 'Active'
          );
          if (isActive) {
            dispatch({ type: GET_BOOKING_ERROR })
            setShowSnackbar({
              show: true,
              message: noFlownTripsText?.title
            });
            return;
          }
          const trips = response?.trips || [];
          const lastFlown = trips?.reverse().find(
            (trip) => trip?.status === 'Flown'
          );
          const skFlight =
            lastFlown?.segments[lastFlown.segments.length - 1]?.flight
              ?.operatingCarrier === 'SK'; // TODO: Check if we need to check marketing carrier as well
          const time = in7DaysWindow(lastFlown?.destination.scheduledTime?.utc);
          const prefill = lastFlown && skFlight && time;
          const isGroupBooking = response?.isGroupBooking;
          const ebDetails = response?.passengers?.find(pax => pax.frequentTraveler?.euroBonus?.length > 0 )?.frequentTraveler?.euroBonus[0]
          const ebNumber = ebDetails ? `${ebDetails?.tier}${ebDetails?.number}` : ''
          if (response?.trips) {
            dispatch({
              type: GET_BOOKING_SUCCESS,
              payload: {
                searchType: 'booking',
                trips: response?.trips,
                originBound: prefill
                  ? lastFlown?.origin?.airport?.city +
                  ' ' +
                  lastFlown?.origin?.airport?.iata
                  : '',
                destinationBound: prefill
                  ? lastFlown?.destination?.airport?.city +
                  ' ' +
                  lastFlown?.destination?.airport?.iata
                  : '',
                passenger:
                  !isGroupBooking && response?.passengers?.length > 0
                    ? response?.passengers[0]
                    : {},
                ...params,
                activeTab: 1,
                baggage: response?.baggageTags?.length > 0 ? response.baggageTags : [],
                isGroupBooking,
                ebNumber
              },
            });
          } else {
            dispatch({ type: GET_BOOKING_ERROR });
            setShowSnackbar({
              show: true,
              message: response?.message || bookingNotFoundText?.description || 'Something went wrong!'
            });
          }
        } else if (params.flightNumber && (params.flightNumber.slice(2,) < 7000  || params.flightNumber.slice(2,) > 7999)) {
          dispatch({ type: GET_BOOKING_ERROR });
          setShowSnackbar({
            show: true,
            message: charterNumberRange?.title || 'Charter Flight number lies in the range SK7000-SK7999. Please use Schedule Flight option.'
          });
        }
         else if (params.flightNumber) {
          const response = await getBagJourney(params);
          const lastPath = `flightNumber=${params.flightNumber}&date=${params.departureDate}&includeCharter=true`;
          let flights = null;
            if (response?.currentBagStatus) {  
            const flightStatus = await checkFlight(lastPath);
            if (flightStatus?.status === 200){
                 flights = flightStatus?.flightStatuses?.map(flight => {
                return mapFlight(flight);
              })
            }
            dispatch({
              type: GET_BOOKING_SUCCESS,
              payload: {
                searchType: 'charter',
                bagTags: [
                  {
                    ...response?.currentBagStatus,
                    number:
                      response?.currentBagStatus?.bagDetails?.bagTagNumber,
                  },
                ],
                flights: flights || [],
                passenger: null,
                ...params,
                activeTab: 1,
                expandSections: {
                  flights: true
                }
              },
            });
          }
          else {
            dispatch({ type: GET_BOOKING_ERROR });
            setShowSnackbar({
              show: true,
              message: response?.message || charterNotFound?.title || 'Something went wrong!'
            });
          }
        }
      } catch (err) {
        console.log(err);
        setShowSnackbar({
          show: true,
          message: err?.message || 'Something went wrong!'
        });
        dispatch({ type: GET_BOOKING_ERROR });
      }
    },
    [dispatch]
  );
  const handleClose = () => setShowSnackbar({ show: false, message: '' });


  useEffect(() => {

      const airports = countryParser(Airports);
      dispatch(
        handleChangeState({
          airports,
        })
      );

  }, [dispatch]);

  useEffect(() => {
    dispatch(handleChangeState({ activeTab: 1, visitedTab: 1 }));
    const params = new URLSearchParams(search);
    const searchedPnr = params.get('pnr');
    const searchedLastName = params.get('lastName');
    const searchedBagtagNumber = params.get('bagTagNumber');
    const searchedFlightNumber = params.get('flightNumber');
    const searchedDate = params.get('departureDate');
    if (searchedPnr && searchedLastName)
      fetchBooking({ pnr: searchedPnr, lastName: searchedLastName });
    else if (searchedBagtagNumber && searchedDate && searchedFlightNumber)
      fetchBooking({
        bagTagNumber: searchedBagtagNumber,
        flightNumber: formatFlightNumber(searchedFlightNumber.replaceAll(' ', '')),
        departureDate: searchedDate,
      });
  }, [search, fetchBooking, dispatch]);

  const content = useMemo(() => {
    const contents = {
      success: {
        title: itineraryText?.title,
        subtitle: itineraryText?.description,
        component: <SearchResults />,
      },
      default: {
        title: retrieveText?.title,
        subtitle: retrieveText?.description,
        component: (
          <SearchForm
            getBookingStatus={getBookingStatus}
            errorMessage={errorMessage}
          />
        ),
      },
      confirmed: {
        title: submittedText?.title,
        subtitle: submittedText?.description,
        component: <Confirmed />,
      },
    };

    return (
      contents[confirmReportStatus] ||
      contents[getBookingStatus] ||
      contents['default']
    );
  }, [
    getBookingStatus,
    confirmReportStatus,
    errorMessage,
    retrieveText,
    itineraryText,
    submittedText,
    showSnackbar
  ]);

  return (
    <div>
      <Loader
        open={[getBookingStatus, confirmReportStatus,getTranslationsStatus].includes('loading')}
      />
      <Snackbar
        open={showSnackbar.show}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          <span
            dangerouslySetInnerHTML={{ __html: showSnackbar.message }}
          ></span>
        </Alert>
      </Snackbar>
      <Grid item position="relative">
        {getBookingStatus !== 'success' && (
          <Box
            component="img"
            src={`https://images.ctfassets.net/oz9qk43ly6yk/24tg22iYNXEZDZzXMqmcqI/b5841461022a260d8708ec6fc4a047da/cover_home2-min.png`}
            width="100%"
            sx={{
              height: {
                md: '800px',
                xs: '550px',
              },
            }}
          />
        )}
        <Grid
          container
          flexDirection="column"
          position={getBookingStatus !== 'success' ? 'absolute' : 'initial'}
          zIndex={1}
          top="0"
        >
          <Typography
            variant="h1"
            color="primary"
            alignItems="center"
            textAlign="center"
            maxWidth="650px"
            m="0 auto 21px"
            sx={{
              p: {
                md: '65px 24px 0',
                xs: '20px 12px 0',
              },
            }}
          >
            {content?.title}
          </Typography>
          <Typography
            variant="p"
            textAlign="center"
            alignItems="center"
            maxWidth="450px"
            sx={{
              m: {
                md: '0 auto 35px',
                xs: '0 auto 20px',
              },
            }}
            p="0 12px"
          >
            <JsxParser
              bindings={{
                choosenContact: notifyBy
                  ?.toLowerCase()
                  .split('_')
                  ?.map((word, index) =>
                    index !== 1
                      ? word.charAt(0).toUpperCase() + word.slice(1)
                      : word
                  )
                  ?.join(' '),
              }}
              jsx={content?.subtitle}
              onError={(e) => console.log(e)}
            />
          </Typography>
          {content?.component}
        </Grid>
      </Grid>
    </div>
  );
};

export default Search;
