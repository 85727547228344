import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './config/theme/fonts.css';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './config';
import { CssBaseline } from '@mui/material';
import SASElements from 'components/SASElements/SASElements';
import { BaggageProvider } from 'context';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: "https://c55400f451ce42a4a5d2970484394cb9@o4504322680225792.ingest.sentry.io/4504328502902784",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BaggageProvider>
        <SASElements />
        <CssBaseline />
        <App />
      </BaggageProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
