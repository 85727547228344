import {
  Typography,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
} from '@mui/material';
import SectionWrapper from 'components/SectionWrapper';
import { useBaggageContext } from 'context';
import { useCallback } from 'react';
import moment from 'moment';

const NotCheckedIcon = () => <s4s-icon name="in--box-empty" />;

const CheckedIcon = () => <s4s-icon name="in--box-checked" />;

const BaggageList = ({
  checkedList,
  handleModal,
  handleExpandSections,
  handleConfirmBaggage,
  handleChangeSelect,
}) => {
  const {
    state: {
      bagTags,
      expandSections,
      texts: {
        'travel-ssdbr-label-baggage': title = '',
        'travel-ssdbr-label-baggage-text': subtitle = '',
        'travel-ssdbr-addBaggage': linkText = '',
        'travel-ssdbr-label-continueButton': button = '',
      } = {},
    },
  } = useBaggageContext();

  const getLabel = useCallback((tag) => {
    if (tag?.bagDetails) {
      if (tag?.outBound && tag?.outBound?.outboundStatusDesc) {
        const obj = tag?.outBound;
        return `${tag?.bagDetails?.bagTagNumber || tag?.number} - ${obj?.outboundStatusDesc} (
          ${obj?.origin}, ${obj?.flightNumber}, ${moment(obj?.scheduledDepartureDate).format(
          'DD MMMM'
        )})`;
      } else if (tag?.inBound && tag?.inBound?.inboundStatusDesc) {
        const obj = tag?.inBound;
        return `${tag?.bagDetails?.bagTagNumber || tag?.number} - ${obj?.inboundStatusDesc} (
          ${obj?.destination}, ${obj?.flightNumber}, ${moment(obj?.scheduledDepartureDate).format(
          'DD MMMM'
        )})`;
      } else return tag?.bagDetails?.bagTagNumber;
    } else return tag?.number;
  }, []);

  return (
    <SectionWrapper
      expand={expandSections.baggage}
      id="baggage-section"
      title={{ jsx: title?.title }}
      summaryText={
        expandSections?.baggage ? '' : Object.keys(checkedList).join(', ')
      }
      handleChange={() => handleExpandSections({ baggage: true })}
      subtitle={subtitle?.description}
      linkClick={() => handleModal(true, 'add-baggage')}
      linkText={linkText?.description}
      button={button?.title}
      buttonClick={() => handleConfirmBaggage()}
      buttonProps={{
        disabled: !Object.keys(checkedList).length,
      }}
    >
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          {bagTags?.map((bag, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  disableRipple
                  icon={<NotCheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  checked={checkedList[bag?.bagDetails?.bagTagNumber || bag?.number] || false}
                  onChange={(e) => handleChangeSelect(e, bag?.bagDetails?.bagTagNumber || bag?.number)}
                />
              }
              label={
                <Typography
                  variant="subtitle3"
                  lineHeight="24px"
                  dangerouslySetInnerHTML={{ __html: getLabel(bag) }}
                />
              }
            />
          ))}
        </FormGroup>
      </FormControl>
    </SectionWrapper>
  );
};

export default BaggageList;
