import {
  Grid,
  Button,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import JsxParser from 'components/JsxParser';
import { useBaggageContext } from 'context';
import SASButton from 'SASButton';

const SectionWrapper = ({
  expand,
  id,
  title,
  summaryText,
  handleChange,
  subtitle,
  linkClick,
  linkText,
  button,
  buttonClick,
  buttonProps = {},
  children,
  style,
}) => {
  const {
    state: {
      texts: { 'travel-ssdbr-label-changeButton': changeButton = '' } = {},
    },
  } = useBaggageContext();

  return (
    <Accordion id={id} expanded={expand} sx={style}>
      <AccordionSummary>
        <Grid
          container
          alignItems="center"
          sx={{
            p: {
              md: expand ? '29px 41px 0' : '29px 41px',
              sm: expand ? '18px 24px 0' : '18px 24px',
              xs: '12px',
            },
            flexDirection: {
              sm: 'row',
              xs: 'column',
            },
          }}
        >
          <Grid item md={4} sm={expand ? 4 : 3} xs={12} lg={expand ? 8 : 6}>
            <Typography
              variant="h5"
              color="primary"
              mb={expand ? '13px' : '0'}
              sx={(theme) => ({
                mr: { sm: 'auto', xs: '0' },
                '& > p': {
                  ...theme?.typography?.h5,
                },
              })}
            >
              <JsxParser
                bindings={title?.bindings}
                jsx={title?.jsx}
                onError={(e) => console.log(e)}
              />
            </Typography>
          </Grid>
          <Grid
            item
            lg={expand ? 4 : 4}
            sm={expand ? 8 : 6}
            xs={12}
            sx={{
              textAlign: { sm: 'end', xs: 'center' },
              p: { sm: '0 10px', xs: '10px' },
            }}
          >
            <Typography
              variant="p"
              sx={{
                m: { sm: '0', xs: '10px 0' },
              }}
              dangerouslySetInnerHTML={{ __html: summaryText }}
            >
            </Typography>
          </Grid>
          <Grid
            item
            lg={expand ? 0 : 2}
            md={expand ? 0 : 2}
            sm={expand ? 0 : 3}
            xs={12}
            textAlign="end"
          >
            {!expand && (
              // <Button
              //   variant="outlined"
              //   color="secondary"
              //   sx={{ width: 'auto' }}
              //   onClick={handleChange}
              // >
              //   {changeButton?.title}
              // </Button>
              <SASButton
                sx={{ width: 'auto' }}
                onClick={handleChange}>{changeButton?.title}</SASButton>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          flexDirection="column"
          sx={{ p: { md: '0px 41px 29px', sm: '0px 24px 18px', xs: '12px' } }}
        >
          {subtitle && (
            <Typography
              variant="p"
              sx={(theme) => ({
                maxWidth: { xs: '100%' },
                mb: { sm: '40px', xs: '20px' },
                '& > p': {
                  ...theme?.typography?.p,
                },
              })}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          )}
          {children}
        </Grid>
        <Divider />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          p="28px 42px"
          sx={{
            flexDirection: {
              sm: 'row',
              xs: 'column',
            },
          }}
        >
          <Typography
            textAlign="center"
            onClick={linkClick}
            color="secondary"
            sx={(theme) => ({
              cursor: 'pointer',
              '& > p': { ...theme.typography.p },
            })}
            dangerouslySetInnerHTML={{ __html: linkText }}
          />
          {/* <Button
            color="secondary"
            variant="contained"
            sx={{
              width: 'auto',
              mt: { sm: '0', xs: '20px' },
            }}
            onClick={buttonClick}
            {...buttonProps}
          >
            {button}
          </Button> */}
          <SASButton
            sx={{
              width: 'auto',
              mt: { sm: '0', xs: '20px' },
            }}
            onClick={buttonClick}
            {...buttonProps}>{button}</SASButton>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SectionWrapper;
