import { Box, Grid, ClickAwayListener } from '@mui/material';
import { useState, useCallback, useMemo } from 'react';
import ArrowDown from '@mui/icons-material/ArrowDropDown';
import ArrowUp from '@mui/icons-material/ArrowDropUp';
import { useBaggageContext } from 'context';
import { config } from 'config';
const ENV = config.ENV;
const host = ENV === 'develop' ? 'test' : 'www';
const countries = [
  { link: `${host}.flysas.com`, title: 'en' },
  { link: `${host}.sas.dk`, title: 'dk' },
  { link: `${host}.sas.no`, title: 'no' },
  { link: `${host}.sas.se`, title: 'se' },
];

const LanguageSelect = () => {
  const [expand, setExpand] = useState(false);
  const {
    state: { activeTab },
  } = useBaggageContext();
  const selected = useMemo(() => {
    const domain = window.location.origin.slice(-2);

    return (
      countries?.find((country) => country?.title === domain) || countries[0]
    );
  }, []);

  const handleClick = useCallback((country) => {
    const path = window.location.pathname;

    window.location.href = `https://${country.link}${path}`;
  }, []);

  return (
    <Box
      sx={{
        display: activeTab === 0 ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'center',
        p: '10px',
        cursor: 'pointer',
        position: 'relative',
      }}
      onClick={() => setExpand((prev) => !prev)}
    >
      <Grid container flexWrap="nowrap">
        <Box
          component="img"
          src={process.env.PUBLIC_URL + `/images/flags/${selected?.title}.png`}
          width="20px"
          height="20px"
        />
        {expand ? <ArrowUp /> : <ArrowDown />}
      </Grid>
      {expand && (
        <ClickAwayListener onClickAway={() => setExpand(false)}>
          <Grid
            container
            flexDirection="column"
            position="absolute"
            zIndex={2}
            top="44px"
            bgcolor="#ffffff"
            boxShadow="0 2px 4px 0 rgba(0,0,0,0.08), 0 0 8px 0 rgba(0,0,0,0.10)"
          >
            {countries
              ?.filter((country) => country?.title !== selected?.title)
              .map((country) => (
                <Box
                  key={country?.title}
                  component="img"
                  src={
                    process.env.PUBLIC_URL +
                    `/images/flags/${country?.title}.png`
                  }
                  width="20px"
                  height="20px"
                  m="10px auto"
                  onClick={() => handleClick(country)}
                  sx={{ cursor: 'pointer' }}
                />
              ))}
          </Grid>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default LanguageSelect;
