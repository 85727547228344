import { Grid, Typography } from '@mui/material';
import { MobileDatePicker, LocalizationProvider } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Fragment } from 'react';
import moment from 'moment';
import Input from 'components/Input';
import AirportAutocomplete from 'components/AirportAutocomplete';
import { useBaggageContext } from 'context';
import { itineraryForm } from 'helpers/validationSchema';
import SASButton from 'SASButton';

const AddItineraryModal = ({ onSubmit, modal = true }) => {
  const {
    state: {
      airports,
      texts: {
        'travel-ssdbr-addItinerary': title,
        'travel-ssdbr-label-from': fromLabel,
        'travel-ssdbr-label-to': toLabel,
        'travel-ssdbr-label-date': dateLabel,
        'travel-ssdbr-label-flightNumber': flightNumberLabel,
        'travel-ssdbr-label-addFlight': addFlight,
        'travel-ssdbr-label-confirmItineraryButton': button,
        'travel-ssdbr-label-removeTempAddress': removeAddress = {
          title: 'Remove',
        },
        'travel-ssdbr-label-error-noDate': noDateError = {
          title: '',
        }
      } = {},
    },
  } = useBaggageContext();

  const informativeTexts = {
    noDateError
  };

  return (
    <Grid
      container
      flexDirection="column"
      sx={{
        p: { xs: '0', sm: '20px 20px 24px' }
      }}
    >
      <Typography
        variant="h6"
        color="primary"
        sx={{ fontSize: '18px', mb: '9px' }}
        dangerouslySetInnerHTML={{ __html: title?.title }}
      />
      <Typography
        variant="p"
        sx={(theme) => ({ mb: '46px', '& > p': { ...theme.typography.p } })}
        dangerouslySetInnerHTML={{ __html: title?.description }}
      />
      <Formik
        initialValues={{
          flights: [
            {
              originCity: '',
              destinationCity: '',
              flightDate: moment().format('YYYY-MM-DD'),
              flightNumber: '',
            },
          ],
        }}
        validationSchema={itineraryForm(informativeTexts)}
        onSubmit={(values) => onSubmit(values, modal)}
        render={({ values, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <FieldArray
              name="flights"
              render={(arrayHelpers) => (
                <div>
                  {values.flights.map((_, index, array) => (
                    <>
                      <Grid
                        container
                        flexWrap="nowrap"
                        sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
                        key={index}
                        mb="10px"
                      >
                        <Field name={`flights[${index}].originCity`}>
                          {({ field, meta }) => (
                            <AirportAutocomplete
                              content={airports}
                              value={values?.flights[index]?.originCity}
                              handleChange={setFieldValue}
                              label={fromLabel?.title}
                              name={`flights[${index}].originCity`}
                              style={{
                                maxWidth: { sm: '181px', xs: 'auto' },
                              }}
                              formik={{
                                errors: {
                                  [field?.name]: meta?.error,
                                },
                                touched: {
                                  [field?.name]: meta?.touched,
                                },
                              }}
                            />
                          )}
                        </Field>
                        <Field name={`flights[${index}].destinationCity`}>
                          {({ field, meta }) => (
                            <AirportAutocomplete
                              content={airports}
                              value={values?.flights[index]?.destinationCity}
                              handleChange={setFieldValue}
                              label={toLabel?.title}
                              name={`flights[${index}].destinationCity`}
                              style={{
                                maxWidth: { sm: '181px', xs: 'auto' },
                              }}
                              formik={{
                                errors: {
                                  [field?.name]: meta?.error,
                                },
                                touched: {
                                  [field?.name]: meta?.touched,
                                },
                              }}
                            />
                          )}
                        </Field>
                        <Field name={`flights.${index}.flightDate`}>
                          {({ field, meta }) => (
                            <LocalizationProvider dateAdapter={DateAdapter}>
                              <MobileDatePicker
                                disableFuture={true}
                                name={`flights.${index}.flightDate`}
                                value={values?.flights[index]?.flightDate}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `flights.${index}.flightDate`,
                                    newValue.format('YYYY-MM-DD')
                                  );
                                }}
                                label={dateLabel?.title}
                                inputFormat="MMM DD"
                                showToolbar={false}
                                renderInput={(params) => (
                                  <Input
                                    params={params}
                                    name={`flights.${index}.flightDate`}
                                    label="Date"
                                    formik={{
                                      errors: {
                                        [field?.name]: meta?.error,
                                      },
                                      touched: {
                                        [field?.name]: meta?.touched,
                                      },
                                    }}
                                    style={{
                                      maxWidth: { sm: '97px', xs: 'auto' },
                                    }}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          )}
                        </Field>
                        <Field name={`flights.${index}.flightNumber`}>
                          {({ field, meta }) => (
                            <Input
                              name={`flights.${index}.flightNumber`}
                              label={flightNumberLabel?.title}
                              value={values?.flights[index]?.flightNumber}
                              handleChange={field?.onChange}
                              formik={{
                                errors: {
                                  [field?.name]: meta?.error,
                                },
                                touched: {
                                  [field?.name]: meta?.touched,
                                },
                              }}
                              style={{
                                mr: '0',
                                maxWidth: { sm: '145px', xs: 'auto' },
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid container justifyContent="space-between" mb="10px">
                        <Typography
                          variant="p"
                          color="secondary"
                          sx={{
                            cursor: 'pointer',
                            display:
                              array?.length - 1 === index ? 'initial' : 'none',
                          }}
                          onClick={() =>
                            arrayHelpers.push({
                              originCity: '',
                              destinationCity: '',
                              flightDate: moment().format('YYYY-MM-DD'),
                              flightNumber: '',
                            })
                          }
                        >
                          {addFlight?.title}
                        </Typography>
                        <Typography
                          textAlign="center"
                          onClick={() => arrayHelpers.remove(index)}
                          color="secondary"
                          sx={{
                            cursor: 'pointer',
                            ml: 'auto',
                            display: array?.length === 1 ? 'none' : 'initial',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: removeAddress?.title,
                          }}
                        />
                      </Grid>
                    </>
                  ))}
                </div>
              )}
            />
            <Grid container>
              {/* <Button
                type="submit"
                color="secondary"
                variant="contained"
                sx={{ width: 'auto', fontSize: '14px', m: '25px 0 0 auto' }}
              >
                {button?.title}
              </Button> */}
              <SASButton type="submit"
                sx={{ width: 'auto', fontSize: '14px', m: '25px 0 0 auto' }}>   {button?.title}</SASButton>
            </Grid>
          </Form>
        )}
      />
    </Grid>
  );
};

export default AddItineraryModal;
