import { forwardRef, useRef } from 'react';
import { Grid, Box } from '@mui/material';
import Input from 'components/Input';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useBaggageContext } from 'context';
import { isValidPhoneNumber } from 'react-phone-number-input';

const CustomPhoneInput = forwardRef((props, ref) => {
  return (
    <Input
      id={props.id}
      name="phoneNumber"
      params={{
        inputRef: ref,
        ...props,
      }}
      style={{
        mr: '0',
        mb: '0',
      }}
      {...props}
    />
  );
});

const Contacts = ({ formik, inputStyle, validationValues }) => {
  const {
    state: {
      texts: {
        'travel-ssdbr-label-name': nameLabel = '',
        'travel-ssdbr-label-lastName': lastNameLabel = '',
        'travel-ssdbr-label-email': emailLabel = '',
        'travel-ssdbr-label-phoneNumber': phoneLabel = '',
      } = {},
    },
  } = useBaggageContext();
  const inputRef = useRef();

  return (
    <Grid container flexWrap="nowrap" flexDirection="column" id="contacts-form">
      <Grid
        container
        flexWrap="nowrap"
        sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
      >
        <Input
          name="firstName"
          label={nameLabel?.title}
          value={formik?.values?.firstName}
          handleChange={formik?.handleChange}
          formik={formik}
          style={inputStyle}
          validvalues={validationValues['firstName']}
        />
        <Input
          name="lastName"
          label={lastNameLabel?.title}
          value={formik?.values?.lastName}
          handleChange={formik?.handleChange}
          formik={formik}
          style={{ mr: '0', ...inputStyle }}
          validvalues={validationValues['lastName']}
        />
      </Grid>
      <Grid
        container
        flexWrap="nowrap"
        sx={{ flexDirection: { sm: 'row', xs: 'column' } }}
      >
        <Input
          name="email"
          label={emailLabel?.title}
          value={formik?.values?.email}
          handleChange={formik?.handleChange}
          formik={formik}
          style={inputStyle}
          validvalues={validationValues['email']}
        />
        <Box
          id="phoneNumber"
          key="phoneNumber"
          name="phoneNumber"
          component={PhoneInput}
          label={phoneLabel?.title}
          formik={formik}
          style={{
            ...inputStyle,
            mr: '0',
            mb: '0',
          }}
          defaultCountry="SE"
          international
          countryCallingCodeEditable={false}
          ref={inputRef}
          value={isValidPhoneNumber(formik?.values?.phoneNumber) ? formik?.values?.phoneNumber : ''}
          validvalues={validationValues['phoneNumber']}
          onChange={(e) => {
            const event = {
              target: {
                value: e || '',
                name: 'phoneNumber',
              },
            };
              formik?.handleChange(event);
            formik.setFieldTouched('phoneNumber', true);
          }}
          inputComponent={CustomPhoneInput}
          sx={{
            width: '100%',
            ...inputStyle,
            height: '57px',
            position: 'relative',
            '& > div.PhoneInputCountry': {
              position: 'absolute',
              zIndex: '1',
              top: '50%',
              left: '5%',
              '@media (max-width: 600px)': {
                left: '3%',
              },
            },
            '&:last-child': {
              mr: '0',
              mb: '0',
              '& > div > input': {
                maxWidth: `calc(${inputStyle?.maxWidth?.sm} - 74px)`,
                pl: '60px',
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Contacts;
