import { Box, Grid, Typography, Link } from '@mui/material';
import { useBaggageContext } from 'context';
// import { useLogin } from 'hooks/useLogin';
import Icon from 'components/Icon';
// import LoggedInStatus from './components/LoggedInStatus';
import LanguageSelect from './components/LanguageSelect';
import { handleChangeState, handleExpandSections } from 'context/actions';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTranslation } from 'api/translation';
import {
  GET_TRANSLATIONS_ERROR,
  GET_TRANSLATIONS_LOADING,
  GET_TRANSLATIONS_SUCCESS,
} from 'context/constants';

const Header = () => {
  const {
    state: {
      activeTab,
      baggageDetails,
      visitedTab,
      texts: {
        'travel-ssdbr-headerHome': headerHome,
        'travel-ssdbr-headerBaggageInformation': headerBaggageInformation,
        'travel-ssdbr-headerBaggageDetails': headerBaggageDetails,
        'travel-ssdbr-headerContactInformation': headerContactInformation,
        'travel-ssdbr-headerConfirmation': headerConfirmation
      } = {},
    },

    dispatch,
  } = useBaggageContext();
  const navigate = useNavigate();
  // const userProfile = useLogin();
  const tabs = [
    headerHome?.title || 'Home',
    // 'Search',
    headerBaggageInformation?.title || 'Baggage information',
    headerBaggageDetails?.title || 'Baggage details',
    headerContactInformation?.title || 'Contact information',
    headerConfirmation?.title || 'Confirmation',
  ];
  const { pathname } = useLocation();

  const goHome = () => (window.location.href = 'https://flysas.com');

  const handleChangeTab = useCallback(
    (index) => {
      if (index === 0) {
        navigate(process.env.PUBLIC_URL);
        dispatch(handleChangeState({ activeTab: index }));
      }
      else if (visitedTab !== 0 && index <= visitedTab) {
        if (activeTab === 0) {
          navigate(process.env.PUBLIC_URL + '/search');
          dispatch(
            handleExpandSections({
              flights: false,
              baggage: false,
              bound: true,
              contactInformation: false,
            })
          );
        }
        else if (index === 1) {
          dispatch(
            handleExpandSections({
              flights: false,
              baggage: false,
              bound: true,
              contactInformation: false,
            })
          );
        } else if (index === 2) {
          dispatch(
            handleExpandSections({
              flights: false,
              baggage: false,
              bound: false,
              contactInformation: false,
              bagsDetails: {
                [Object.keys(baggageDetails)[0]]: {
                  show: true,
                  accepted: false,
                },
              },
            })
          );
          setTimeout(() => {
            const wrapper = document.getElementById(`baggage-details-${Object.keys(baggageDetails)[0]}-section`);
            if (wrapper) wrapper.scrollIntoView({ behavior: 'smooth' });
          }, 500)
        } else if (index === 3) {
          dispatch(
            handleExpandSections({
              flights: false,
              baggage: false,
              contactInformation: true,
            })
          );
          setTimeout(() => {
            const wrapper = document.getElementById(`contact-information-section`);
            if (wrapper) wrapper.scrollIntoView({ behavior: 'smooth' });
          }, 500)
        }
        if (index !== 4) dispatch(handleChangeState({ activeTab: index }));
      }
    },
    [dispatch, navigate, baggageDetails, activeTab, visitedTab]
  );

  const getTexts = useCallback(async () => {
    try {
      dispatch({ type: GET_TRANSLATIONS_LOADING });
      const response = await getTranslation();
      if (response?.informationItems) {
        let result = response.informationItems?.reduce(
          (prev, curr) =>
          curr?.id ? ({ ...prev, [curr?.id]: curr }) : ({ ...prev, [`${Object.keys(curr)[0]}List`]: Object.values(curr) }),
          {}
        );

        let labelSet = result["label-set-travel-ssdbr"];

        for (const [key, value] of Object.entries(labelSet["labels"])) {
          result[key] = {"id": key, "title": value};
        }

        dispatch({
          type: GET_TRANSLATIONS_SUCCESS,
          payload: {
            texts: {
              ...result
            }
          },
        });
      }
      else dispatch({ type: GET_TRANSLATIONS_ERROR });
    } catch (err) {
      dispatch({ type: GET_TRANSLATIONS_ERROR });
      console.log(err);
    }
  }, [dispatch]);

  useEffect(() => getTexts(), [getTexts]);

  return (
    <Box
      component="header"
      width="100%"
      p="0 var(--margin-normal)"
      sx={{ bgcolor: ({ palette }) => palette.primary.main }}
    >
      <Grid
        container
        maxWidth="1152px"
        flexWrap="nowrap"
        height="var(--header-height)"
        minHeight="4rem"
        margin="auto"
        alignItems="center"
        color="var(--white-color)"
        p="0 var(--margin-normal)"
        sx={{
          justifyContent: {
            md: 'initial',
            xs: 'space-between',
          },
        }}
      >
        <Link
          p="0 var(--margin-normal)"
          cursor="pointer"
          display="flex"
          onClick={goHome}
        >
          <Icon name="logo" />
        </Link>
        <Grid
          container
          flexWrap="nowrap"
          width="fit-content"
          sx={{ display: { xs: 'none', sm: 'flex' } }}
        >
          {pathname !== '/travel/bags/contacts' &&
            tabs.map((tab, index) => (
              <Box
                key={tab}
                alignItems="center"
                onClick={activeTab !== 4 ? () => handleChangeTab(index) : null}
                sx={{
                  cursor: (index === 4 || visitedTab === 4) ? 'default' : index <= visitedTab ? 'pointer' : 'default',
                  display: {
                    md: 'flex',
                    xs: tabs[activeTab] === tab ? 'initial' : 'none',
                  },
                  flexDirection: {
                    md: 'row',
                    xs: 'column',
                  },
                  textAlign: {
                    md: 'initial',
                    xs: 'center',
                  },
                  p: {
                    lg: '0 24px',
                    xs: '0 12px',
                  },
                  opacity: tabs[activeTab] === tab ? '1' : '0.4',
                  position: 'relative',
                  '&::after': {
                    position: 'absolute',
                    bottom: '-2px',
                    content: "''",
                    width: {
                      lg: tabs[activeTab] === tab ? 'calc(100% - 48px)' : '0',
                      md: tabs[activeTab] === tab ? 'calc(100% - 24px)' : '0',
                    },
                    height: '1px',
                    bgcolor: '#ffffff',
                  },
                }}
              >
                <Box
                  sx={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontFamily: ({ typography }) => typography.fontFamily,
                    color: '#ffffff',
                    mr: '5px',
                  }}
                >
                  {index + 1}
                  <Typography
                    sx={{
                      display: {
                        md: 'initial',
                        xs: 'none',
                      },
                    }}
                  >
                    .
                  </Typography>
                  <Typography
                    sx={{
                      display: {
                        md: 'none',
                        xs: 'initial',
                      },
                      ml: '3px',
                      opacity: '0.4',
                    }}
                  >
                    of 5
                  </Typography>
                </Box>
                <Typography variant="p" color="#ffffff">
                  {tab}
                </Typography>
              </Box>
            ))}
        </Grid>
        {pathname !== '/travel/bags/contacts' && (
          <Grid
            container
            alignItems="center"
            width="fit-content"
            height="100%"
            flexWrap="nowrap"
            sx={{
              ml: {
                md: 'auto',
                xs: '0',
              },
              '& > a > svg': {
                color: '#fff',
                ml: '10px',
                cursor: 'pointer',
              },
            }}
          >
            <LanguageSelect />
            {/* <LoggedInStatus userProfile={userProfile} /> */}
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Header;
