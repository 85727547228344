import { Grid, Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useBaggageContext } from 'context';

const BaggageColorSelect = ({ name, handleChange, formik, checkedBag }) => {
  const {
    state: {
      texts,
      texts: {
        'travel-ssdbr-label-error-bagColorRequired': errorLabel = '',
      } = {},
    },
  } = useBaggageContext();

  const colorsImages = ["white", "black", "grey", "blue", "purple", "red", "yellow", "beige", "brown", "green", "multi-colored", "pattern"]

  const colors = useMemo(
    () => [
      { code: 'WT', title: texts['travel-ssdbr-label-color-white']?.title },
      { code: 'BK', title: texts['travel-ssdbr-label-color-black']?.title },
      { code: 'GY', title: texts['travel-ssdbr-label-color-grey']?.title },
      { code: 'BU', title: texts['travel-ssdbr-label-color-blue']?.title },
      { code: 'PU', title: texts['travel-ssdbr-label-color-purple']?.title },
      { code: 'RD', title: texts['travel-ssdbr-label-color-red']?.title },
      { code: 'YW', title: texts['travel-ssdbr-label-color-yellow']?.title },
      { code: 'BE', title: texts['travel-ssdbr-label-color-beige']?.title },
      { code: 'BN', title: texts['travel-ssdbr-label-color-brown']?.title },
      { code: 'GN', title: texts['travel-ssdbr-label-color-green']?.title },
      { code: 'MC', title: texts['travel-ssdbr-label-color-multi']?.title },
      { code: 'PR', title: texts['travel-ssdbr-label-color-pattern']?.title },
    ],
    [texts]
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: {
            md: 'repeat(5, 1fr)',
            sm: 'repeat(4, 1fr)',
            xs: 'repeat(2, 1fr)',
          },
        }}
      >
        {colors.map((color, index) => (
          <Grid
            item
            key={color?.code}
            sx={{
              cursor: 'pointer',
              p: formik?.values[name] === color?.code ? '18px' : '20px',
              maxWidth: { sm: '190px', xs: '100%' },
              width: '100%',
              border:
                formik?.values[name] === color?.code
                  ? '3px solid #006ee6'
                  : '1px solid rgba(0, 0, 0, 0.2)',
            }}
            onClick={() => handleChange(color)}
          >
            <Typography variant="p" fontSize="12px">
              {color?.title}
            </Typography>
            <Box
              component="img"
              src={
                process.env.PUBLIC_URL +
                `/images/colors/${colorsImages[index]?.toLowerCase()}.png`
              }
              sx={{
                width: { sm: '150px', xs: '100%' },
                height: '34px',
                mt: '10px',
              }}
            />
          </Grid>
        ))}
      </Box>
      <Typography
        id={`baggage-color-err-${checkedBag}`}
        color="error"
        mt="10px"
        dangerouslySetInnerHTML={{
          __html:
            formik?.touched[name] && Boolean(formik?.errors[name])
              ? errorLabel?.description
              : '<p></p>',
        }}
      />
    </Box>
  );
};

export default BaggageColorSelect;
