
const countryParser = (data) => {
  if (data) {

    const airports = Object.entries(data).map(([key, value]) => ({
      country_name: value.country,
      country_code: value.countryCode,
      city_name: value.city,
      airport_name: value.name,
      airport_code: value.iata,
      label: `${value?.city} ${value?.iata}`
    }))

    return airports;
  }
};

export default countryParser;
