import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const fallbackFonts = ['sans-serif', 'arial', 'Open Sans'];
const scandinavian = ['ScandinavianRegular', ...fallbackFonts].join(',');
const scandinavianBlack = ['scandinavianblack', ...fallbackFonts].join(',');
const scandinavianBold = ['scandinavianbold', ...fallbackFonts].join(',');

// SAS theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#000099', // B3
    },
    secondary: {
      main: '#006ee6', // B1
    },
    error: {
      main: '#e81e14',
    },
    background: {
      default: '#fff',
      blue: '#0033cc',
      grey: '#f4f4f4',
      divider: '#c3c2be',
    },
    text: {
      primary: '#3f3d3a', // G5
      secondary: '#68645c', // G4
    },
  },
  typography: {
    fontFamily: scandinavian,
    fontFamilyBold: scandinavianBold,
    fontFamilyBlack: scandinavianBlack,
    button: {
      fontFamily: scandinavianBlack,
      fontSize: '14px',
     
    },
    h6: {
      fontFamily: scandinavianBlack,
      textTransform: 'uppercase',
      fontSize: '16px',
    },
    h5: {
      fontFamily: scandinavianBlack,
      textTransform: 'uppercase',
      fontSize: '24px',
    },
    h4: {
      fontFamily: scandinavianBlack,
      textTransform: 'uppercase',
      fontSize: '32px',
    },
    h3: {
      fontFamily: scandinavianBlack,
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: scandinavianBlack,
      textTransform: 'uppercase',
    },
    h1: {
      fontFamily: scandinavianBlack,
      textTransform: 'uppercase',
      fontSize: '32px',
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: '#006ee6',
      WebkitTapHighlightColor: 'transparent',
    },
    p: {
      fontFamily: scandinavian,
      fontSize: '16px',
    },
    subtitle1: {
      fontSize: '18px',
      color: '#000000',
    },
    subtitle2: {
      fontSize: '16px',
      lineHeight: '20px',
    },
    subtitle3: {
      fontSize: '18px',
      color: '#000000',
    },
    boldText: {
      textTransform: 'uppercase',
      fontFamily: scandinavianBlack,
      fontSize: '14px',
      lineHeight: '16px',
    },
    eurobonusTitle: {
      fontFamily: scandinavianBold,
      fontSize: '14px',
      lineHeight: '16px',
    },
    baggageList: {
      fontFamily: scandinavianBold,
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent'
        }
        
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #e8e8e8',
        },
        indicator: {
          backgroundColor: '#000099',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginRight: '15px',
          fontFamily: scandinavian,
          fontSize: '14px',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-selected': {
            color: '#000099',
            fontFamily: scandinavianBold,
          },
          '&:focus': {
            color: '#000099',
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
          minHeight: 'unset',
          '&.Mui-disabled': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, 0.2)',
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '40px',

          '&.Mui-expanded': {
            marginBottom: '92px',

            '@media (max-width: 600px)': {
              marginBottom: '30px',
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0',
          cursor: 'default',
          '&.Mui-expanded': {
            minHeight: '0',
          },
          '& > div.Mui-expanded': {
            margin: '0',
            cursor: 'default',
          },
        },
        content: {
          margin: '0',
          display: 'block',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          padding: '8px 32px',

          '@media (max-width: 600px)': {
            fontSize: '14px',
            padding: '8px 16px',
          },
          '&.Mui-disabled': {
            backgroundColor: '#d7d7d7',
            color: '#fff',
          },
        },
        outlinedSecondary: {
          borderWidth: '2px',
          borderColor: '#006ee6',
          padding: '4px 32px',
          minHeight: 'unset',

          '&:hover': {
            borderWidth: '2px',
          },
          '&:focus': {
            backgroundColor: '#fff',
            boxShadow: 'blue !important',
          },
        },
        containedSecondary: {
          width: '100%',
          fontSize: '14px',
          '&:hover': {
            backgroundColor: '#000099',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#68645c',
          },
          '&.Mui-focused, &.MuiFormLabel-filled': {
            transform: 'translate(14px, 4px) scale(.8)',
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          lineHeight: '20px',
          fontSize: '14px',

          '@media (max-width: 600px)': {
            lineHeight: '18px',
            fontSize: '12px',
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: '8px',
          color: '#c0bcb4',
          '&.Mui-checked': {
            color: '#0033cc',
          },
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '& .MuiSvgIcon-root': {
            fontSize: 22,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        variant: 'outlined',
        InputLabelProps: {
          shrink: false,
        },
      },
      styleOverrides: {
        root: {
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: '4px',
          marginRight: '10px',
          marginBottom: '20px',

          '@media (max-width: 600px)': {
            marginRight: '0',
            marginBottom: '10px',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#bfbcb5',
              borderWidth: '1px',
            },
          },
        },
        input: {
          maxWidth: 'calc(100% - 28px)',
          padding: '24px 14px 10px',
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&::placeholder': {
            color: 'rgb(104, 100, 92)',
            fontSize: '14px',
            fontWeight: 200,
            opacity: 1,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '& > .MuiOutlinedInput-notchedOutline': {
            borderColor: '#bfbcb5',
            borderWidth: '1px',
            top: '0',
            '& > legend': {
              display: 'none',
              height: '0px'
            },
          },
        },
        input: {
          padding: '15px 5px 1px !important',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
