import { Paper, Tabs, Tab, Typography } from '@mui/material';
import { useBaggageContext } from 'context';
import { handleChangeState } from 'context/actions';
import { useCallback } from 'react';
import ScheduleFlight from './components/ScheduleFlight';
import CharterFlight from './components/CharterFlight';
import { useBackListener } from 'hooks/useBackListener';

const SearchForm = ({ getBookingStatus, errorMessage }) => {
  const {
    state: {
      searchTab = 0,
      texts: {
        'travel-ssdbr-label-scheduleFlight': firstTab,
        'travel-ssdbr-label-charterFlight': secondTab,
      } = {},
    },
    dispatch,
  } = useBaggageContext();

  const handleChangeTab = useCallback(
    (e, tab) => dispatch(handleChangeState({ searchTab: tab })),
    [dispatch]
  );

  useBackListener(()=>
    dispatch(handleChangeState({activeTab : 0}))
  )

  return (
    <Paper
      sx={{
        p: { sm: '48px 55px', xs: '20px 10px' },
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.08), 0 0 8px 0 rgba(0,0,0,0.10)',
        borderRadius: '0',
        backgroundColor: '#ffffff',
        width: '100%',
        maxWidth: { sm: '700px', xs: '400px' },
        m: '50px auto 0',
      }}
    >
      <Tabs value={searchTab} onChange={handleChangeTab} sx={{ mb: '30px' }}>
        {[firstTab,  secondTab ].map((tab, index) => (
          <Tab key={index} label={tab?.title} disableRipple sx={{ mr: '0' }} />
        ))}
      </Tabs>
      {searchTab === 0 ? <ScheduleFlight /> : <CharterFlight />}
      <Typography variant="p" fontSize="12px" color="error" mt="10px">
        {getBookingStatus === 'error' ? errorMessage : ''}
      </Typography>
    </Paper>
  );
};

export default SearchForm;
