import {
  HANDLE_MODAL,
  HANDLE_HEADER_TAB,
  HANDLE_CHANGE_STATE,
  HANDLE_CHANGE_BOOKING_BAGGAGE,
  HANDLE_CHANGE_BOOKING_FLIGHTS,
  HANDLE_CHANGE_BAGGAGE_DETAILS,
  HANDLE_CHANGE_EXPAND_SECTIONS,
  HANDLE_CHANGE_BAGGAGE_STATUS,
  RESET_CONTEXT,
  HANDLE_CHANGE_CUSTOMS_DECLARATION,
} from 'context/constants';

export const handleChangeState = (state) => ({
  type: HANDLE_CHANGE_STATE,
  payload: state,
});

export const handleChangeModal = (open, target) => ({
  type: HANDLE_MODAL,
  payload: {
    open,
    target,
  },
});

export const handleChangeTab = (tab) => ({
  type: HANDLE_HEADER_TAB,
  payload: tab,
});

export const handleAddFlights = (flights) => ({
  type: HANDLE_CHANGE_BOOKING_FLIGHTS,
  payload: flights,
});

export const handleAddBaggage = (bags) => ({
  type: HANDLE_CHANGE_BOOKING_BAGGAGE,
  payload: bags,
});


export const handleBaggageDetails = (details) => ({
  type: HANDLE_CHANGE_BAGGAGE_DETAILS,
  payload: details,
});

export const handleExpandSections = (sections) => ({
  type: HANDLE_CHANGE_EXPAND_SECTIONS,
  payload: sections,
});

export const resetContext = () => ({
  type: RESET_CONTEXT,
});

export const handleChangeDeclaration = (declaration) => ({
  type: HANDLE_CHANGE_CUSTOMS_DECLARATION,
  payload: {
    customDeclaration: declaration
  }
})