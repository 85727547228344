import React from 'react';
import PropTypes from 'prop-types';

import LogoIcon from './Logo.icon';

const SVGs = {
  logo: LogoIcon,
};

const Icon = ({ name, ...props }) => {
  const Component = SVGs[name];

  return <Component {...props} />;
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(SVGs)),
};

export default Icon;
