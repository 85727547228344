import { useCallback } from 'react';
import Input from 'components/Input';
import Autocomplete from '@mui/material/Autocomplete';

const AirportAutocomplete = ({
  content,
  value,
  handleChange,
  label,
  name,
  style,
  formik,
  wrapperStyle,
}) => {

  const handleSelectInput = useCallback(
    (value) => {
      const { city_name, airport_code } = value;
      handleChange(name, `${city_name}${airport_code}`?.trim());
    },
    [handleChange, name]
  );

  return (
    <Autocomplete
      freeSolo
      options={content}
      value={value}
      // defaultValue={value}
      disableClearable
      onChange={(e) => {
        handleSelectInput({
          city_name: e.target?.textContent?.slice(0, -3),
          airport_code: e.target?.textContent?.slice(-3),
        });
      }}

      sx={{
        maxWidth: { sm: '181px', xs: 'auto' },
        width: '100%',
        mr: '10px',
        ...wrapperStyle,
      }}

      renderInput={(params) => (
        <>
          {/* {console.log(params)} */}
          <Input
            name={name}
            fullWidth
            handleChange={params?.inputProps?.onChange}
            label={label}
            params={{
              ...params,
            }}
            style={style}
            formik={formik}
          />
        </>
      )}
    />
  );
};

export default AirportAutocomplete;
